import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getViews } from "../../actions/viewAction";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import "./Navbar.css";
import { getUserStatus } from "../../actions/auth";
import { getBatchManagers } from "../../actions/admin";

const Navbar = () => {
  const dispatch = useDispatch();

  const userStatus = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getViews());
    dispatch(getUserStatus());
    dispatch(getBatchManagers());
  }, [dispatch, userStatus]);

  return (
    <div className="nav-div">
      <Link to="/" className="nav-left">
        <img src={`${window.location.origin}/accio-logo.svg`}></img>
        <p>AccioJob</p>
      </Link>
      <div>
        <Button
          component={Link}
          to="/jobCardDetails"
          variant="contained"
          color="primary"
          sx={{
            marginRight: "10px",
            height: "30px",
            padding: "2px",
            fontSize: 15,
          }}
        >
          Job Card Details
        </Button>
      </div>
    </div>
  );
};

export default Navbar;
