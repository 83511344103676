import React from "react";
import "./index.css";
import App from "./App";
import { createStoreHook, Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from 'redux-thunk';
import { createStateSyncMiddleware, initStateWithPrevTab ,initMessageListener} from 'redux-state-sync'
import reducers from './reducers';
import ReactDOM from "react-dom";
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const config = {
    whitelist:['GETFULLDATAOFSTUDENTS']
}
const middlewares = [
  createStateSyncMiddleware(config),
]


const store = createStore(reducers , composeEnhancers(applyMiddleware(thunk,...middlewares)));
initStateWithPrevTab(store)
const root = ReactDOM.render(
<Provider store={store}>
    <App />
</Provider>,document.getElementById("root"));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

