const initialState = [];
export default (studentCoursesState = initialState, action) => {
  switch (action.type) {
    case "GET_COURSES_OF_A_STUDENT":
      return action.payload;
    case "Add assignment data":
      const newData = studentCoursesState.map((studentCourse) => {
        if (studentCourse.courseId == action.courseId) {
          studentCourse = {
            ...studentCourse,
            assignmentData: `${action.payload.totalSolvedCodingQuestionAfterViewingTestCase}, ${action.payload.totalSolvedAssignmentQuestions} / ${action.payload.totalAssignmentQuestions}`,
          };
        }
        return studentCourse;
      });
      return newData;

    case "REMOVE_COURSES_OF_A_STUDENT":
      return [];

    default:
      return studentCoursesState;
  }
};
