import WorkIcon from "@mui/icons-material/Work";
import axios from "axios";
import { useState, type FC } from "react";
import { useNavigate } from "react-router";
import {
  editJobCardDetails,
  updateJobCardEligibilityToState,
} from "../../actions/jobCard";
import { getMainPortalJobCard, publishJobCard } from "../../api";
import { rootEndpoint } from "../../config/endpoint";
import { useAppDispatch } from "../../hooks";
import styles from "./PlacementDashboard.module.css";
import { Button } from "./components/Button";
import { JobCardDetailsType, JobCardType } from "./types/JobCardDetails";
import { formatDate } from "./utils";

type JobCardDetailModalPropsType = {
  jobCard: JobCardDetailsType | null;
  onClose: () => void;
  onCloned: (clonedJobCard: JobCardType) => void;
};

export const JobCardDetailModal: FC<JobCardDetailModalPropsType> = ({
  jobCard,
  onClose,
  onCloned,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [addJobBtnLoading, setEditJobBtnLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [cloning, setCloning] = useState(false);

  const getCompleteDetailsOfJobCard = async () => {
    try {
      const { data } = await axios.get(
        `${rootEndpoint}/job-card/getCompleteJobCardFromJobCardId?jobCardId=${
          jobCard!.id
        }`
      );
      dispatch(editJobCardDetails(data));
      dispatch(updateJobCardEligibilityToState(data.jobCardEligibility));
      navigate("/companyForm");
    } catch (error) {
      console.error("Error fetching job card details:", error);
    }
  };

  const publishJobCardFunction = async () => {
    const data = await publishJobCard({ jobCardId: jobCard!.id });
  };
  const getMainPortalJobCardFunction = async () => {
    const data = await getMainPortalJobCard(jobCard!.id);
  };

  const downloadjobCardDetails = async () => {
    setDownloading(true);
    try {
      const response = await axios.get(
        `${rootEndpoint}/job-card/download/${jobCard!.id}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "filename.txt");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDownloading(false);
    } catch (error) {
      console.error("Error downloading the file:", error);
      setDownloading(false);
    }
  };

  const cloneJobCard = async () => {
    setCloning(true);
    try {
      const response = await axios.post(
        `${rootEndpoint}/job-card/cloneJobCardForInternalTool`,
        {
          jobCardId: jobCard?.id,
        }
      );

      setCloning(false);
      onCloned(response.data);
    } catch (error) {
      console.error("Error cloning the job card:", error);
      setCloning(false);
    }
  };

  const onEditJobCard = async () => {
    setEditJobBtnLoading(true);
    try {
      await getCompleteDetailsOfJobCard();
    } finally {
      setEditJobBtnLoading(false);
    }
  };

  if (!jobCard) return null;

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 100000,
        top: 0,
        left: 0,
        width: "100dvw",
        height: "100dvh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div
        className={styles.jobCard}
        style={{
          maxHeight: "80vh",
          maxWidth: "80vw",
          padding: "20px",
          position: "relative",
          background: "rgb(230, 230, 230)",
        }}
      >
        <div style={{ position: "absolute", top: -20, right: -20 }}>
          <button
            onClick={onClose}
            style={{
              padding: "8px 12px",
              border: "none",
              cursor: "pointer",
              background: "transparent",
              color: "black",
              fontWeight: "normal",
              fontSize: "16px",
              borderRadius: "50%",
              backgroundColor: "white",
            }}
            disabled={addJobBtnLoading}
          >
            X
          </button>
        </div>
        <div style={{ overflow: "auto" }}>
          <h3>
            <span>
              <WorkIcon />
            </span>
            {jobCard.companyDetails.companyName} - {jobCard.driveName}
          </h3>
          <h4>
            HR Name - <span>{jobCard.companyDetails.hrName}</span>
          </h4>
          <h4>
            HR Contact Number -{" "}
            <span>{jobCard.companyDetails.hrContactNumber}</span>
          </h4>
          <h4>
            HR Email Id - <span>{jobCard.companyDetails.hrEmailId}</span>
          </h4>
          <h4>
            Deadline - <span>{formatDate(jobCard.deadline)}</span>
          </h4>
          <h4>
            Application Link -{" "}
            <a
              href={"https://refs.acciojob.com/jobs?job=" + jobCard.id}
              target="_blank"
              rel="noreferrer"
            >
              Link
            </a>
          </h4>
          <h4>
            IsPublished - <span>{jobCard.isPublished ? "True" : "False"}</span>
          </h4>
          <h4>
            IsSynced - <span>{jobCard.isSynced ? "True" : "False"}</span>
          </h4>
          <h4>
            IsHidden - <span>{jobCard.isHidden ? "True" : "False"}</span>
          </h4>
          <h4>
            isOnHold - <span>{jobCard.isOnHold ? "True" : "False"}</span>
          </h4>
          {jobCard.endAt ? (
            <h4>
              End Date - <span>{formatDate(jobCard.endAt)}</span>
            </h4>
          ) : null}

          {jobCard?.opsPOC ? (
            <h4>
              OPS POC - <span>{jobCard.opsPOC}</span>
            </h4>
          ) : null}

          {jobCard?.reachoutTeamPOC ? (
            <h4>
              Reachout Team POC - <span>{jobCard.reachoutTeamPOC}</span>
            </h4>
          ) : null}

          <Button title="Edit" onClick={onEditJobCard} />
          {jobCard.isPublished && (
            <Button
              disabled={addJobBtnLoading}
              title="Change Stage"
              onClick={() => {
                navigate(`/studentEmails?jobCardId=${jobCard.id}`);
              }}
            />
          )}
          {!jobCard.isSynced && (
            <Button
              disabled={addJobBtnLoading}
              title="Publish"
              onClick={publishJobCardFunction}
            />
          )}
          {!jobCard.isSynced && (
            <Button
              disabled={addJobBtnLoading}
              title="Get Main Portal Job-Card"
              onClick={getMainPortalJobCardFunction}
            />
          )}

          <Button
            disabled={addJobBtnLoading}
            title={downloading ? "Downloading..." : "Download Details"}
            onClick={downloadjobCardDetails}
          />
          <Button
            disabled={cloning}
            title={cloning ? "Cloning..." : "Clone"}
            onClick={cloneJobCard}
          />
        </div>
      </div>
    </div>
  );
};
