

const initialState=[];
export default (mocksDataState = initialState , action) =>{
    switch(action.type){
        case 'GET_MOCKS_OF_A_STUDENT':
            return action.payload;
            case 'REMOVE_MOCKS_OF_A_STUDENT':
                return [];

        default :
            return mocksDataState;
    }
}
            