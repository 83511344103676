import XIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Input } from "@mui/material";
import { CSSProperties, useState, type FC } from "react";


type SearchBoxPropsType = {
  onSearch: (searchText: string) => void;
  onClear: () => void;
}

const styles: Record<string, CSSProperties> = {
  searchBox: {
    margin: "0px 32px",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "stretch",
    gap: 12,
    border: "1px solid #ccc",
    padding: "4px 8px",
    borderRadius: 4
  },
  input: {
    flexGrow: 1,
    borderWidth: 0,
    outlineWidth:0
  },
  inputEnd: {
    cursor: "pointer"
  },
  button: {
    padding: "8px 24px",
    cursor: "pointer",
    borderRadius: 4,
    backgroundColor: "#ccc"
  }
}

export const SearchBox: FC<SearchBoxPropsType> = ({ onSearch, onClear }) => {
  const [searchText, setSearchText] = useState<string>("");

  return <div style={styles.searchBox}>
    <Input
      style={styles.input}
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      endAdornment={<div style={styles.inputEnd} onClick={() => {
        setSearchText("");
        onClear();
      }}><XIcon /></div>}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onSearch(searchText);
        }
      }}
    />
    <div
      style={styles.button}
      onClick={() => onSearch(searchText)}
    >
      <SearchIcon/>
    </div>
  </div>
}
