import { TextField as MaterialTextField } from "@mui/material";
import { FormDataType, FormFieldProps } from "../CompanyForm";

const TextField = ({
  id,
  label,
  updateFormData,
  formData,
  disabled,
}: FormFieldProps) => {
  return (
    <div className='form-item'>
      <MaterialTextField
        required={disabled === true ? false : true}
        disabled={disabled === true ? true : false}
        id='outlined-basic'
        label={label}
        variant='outlined'
        style={{ width: "100%" }}
        name={id}
        value={formData[id as keyof FormDataType]}
        onChange={(e) => updateFormData(e.target.name, e.target.value)}
      />
    </div>
  );
};

export default TextField;
