const initialState = false;

export default (authState = initialState, action) => {
  switch (action.type) {
    case "GET_USER_STATUS":
      let res = false;
      if (localStorage.getItem("BMId")) {
        res = true;
      }
      return res;

    case "REMOVE_USER_STATUS":
      localStorage.clear();
      return false;
    default:
      return authState;
  }
};
