import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FormDataType, FormFieldProps } from "../CompanyForm";

const SelectField = ({
  id,
  label,
  items,
  updateFormData,
  formData,
  disabled,
}: FormFieldProps) => {
  return (
    <div className='form-item'>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          required={disabled === true ? false : true}
          disabled={disabled === true ? true : false}
          value={formData[id as keyof FormDataType]}
          name={id}
          label={label}
          onChange={(e) => {
            updateFormData(e.target.name, e.target.value);
          }}
        >
          {items?.map((item, idx) => {
            // @ts-ignore
            const label = item.label || item;
            // @ts-ignore
            const value = item.value || item;
            return <MenuItem key={idx} value={value}>
              {label}
            </MenuItem>
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectField;
