import * as api from "../api";

export const getFullDataOfStudents = () => async (dispatch) => {
  try {
    const { data } = await api.getFullDataOfStudents();
    dispatch({ type: "GETFULLDATAOFSTUDENTS", payload: data });
  } catch (error) {
    console.error(error);
  }
};
