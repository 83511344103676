import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField as MaterialTextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { FormEventHandler, ReactElement, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../App";
import {
  postJobCardData,
  updateJobCardDataToState,
  updateJobCardEligibilityToState,
} from "../../actions/jobCard";
import * as api from "../../api";
import { rootEndpoint } from "../../config/endpoint";
import CustomQuestions from "./CustomQuestions";
import CheckBox from "./formElements/CheckBox";
import MultiSelectField from "./formElements/MultiSelectField";
import SelectField from "./formElements/SelectField";
import TextField from "./formElements/TextField";
import "./styles.css";

export interface FormDataType {
  driveName: string;
  rolesOffered: string[];
  selectionProcess: string[];
  minCtcOffered: number;
  maxCtcOffered: number;
  salaryComments: string;
  noOfPositions: string;
  location: string[];
  jd: string;
  otherCodingSkills: string[];
  resources: string[];
  deadline: Dayjs | null;
  companyDetails: string;
  isBondOffering: boolean;
  isHidden: boolean;
  opsPOC: string;
  reachoutTeamPOC: string;
}

export interface FormFieldProps {
  id: string;
  label: string;
  type: string;
  items?: Array<string>;
  updateFormData: Function;
  formData: FormDataType;
  required?: boolean;
}

const FormFields = {
  rolesOffered: {
    label: "Roles Offered",
    type: "multiselect",
    items: ["Software Developement"],
    required: true,
  },
  driveName: {
    label: "Drive Name",
    type: "text",
    required: true,
  },
  selectionProcess: {
    label: "Steps in the hiring process",
    type: "multiselect",
    items: [
      "Resume shortlist",
      "Telephonic Screening",
      "Coding Assignment",
      "Psychometric Test",
      "Aptitude Test",
      "Programming Test",
      "Aptitude + Programming Test",
      "Technical Interview 1",
      "Technical Interview 2",
      "Technical Interview 3",
      "HR Discussion",
      "Disqualified (out of selection process)",
      "Company Assignment",
    ],
    required: true,
  },
  minCtcOffered: {
    label: "Min CTC Offered",
    type: "number",
    required: true,
  },
  maxCtcOffered: {
    label: "Max CTC Offered",
    type: "number",
    required: true,
  },
  salaryComments: {
    label: "Salary Comments",
    type: "text",
    required: true,
  },
  noOfPositions: {
    label: "Number of positions",
    type: "text",
    required: true,
  },
  location: {
    label: "Location(s)",
    type: "multiselect",
    items: ["Delhi", "Mumbai", "Bangalore", "Hyderabad", "Chennai"],
    required: true,
  },
  otherCodingSkills: {
    label: "Skills Required",
    type: "multiselect",
    items: ["temp"],
    required: true,
  },
  isBondOffering: {
    label: "Is Bond Offering",
    type: "checkbox",
    required: true,
  },
  isHidden: {
    label: "Is Hidden?",
    type: "checkbox",
    required: true,
  },
  opsPOC: {
    label: "OPS POC",
    type: "text",
    required: false,
  },
  reachoutTeamPOC: {
    label: "Reachout Team POC",
    type: "text",
    required: false,
  },
};

// custom question types
export enum QuestionType {
  Checkbox = "MULTIMCQ",
  Radio = "MCQ",
  Text = "TEXT",
}

export interface CustomQuestion {
  questionString: string;
  questionType: QuestionType;
  answerOptions: string[];
  jobCard: string;
  correctAnswer?: string;
  id?: string;
}

const JobDetailsForm: React.FC = (): ReactElement => {
  const [formFields, setFormFields] = useState(FormFields);
  const [loading, setLoading] = useState(false);

  // global state and navigation
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const companyDataState = useSelector(
    (state) => ((state as any).jobCard as any).companyData
  );
  const jobCardDataState = useSelector(
    (state) => ((state as any).jobCard as any).jobCardData
  );
  const eligibilityJobCardDataState = useSelector(
    (state) => ((state as any).jobCard as any).eligibilityData
  );

  const tracks = useSelector((state) => ((state as any).jobCard as any).tracks);

  useEffect(() => {
    const getMultiSelectValues = async () => {
      try {
        const { data } = await axios.get(
          `${rootEndpoint}/job-card/multiSelectValuesjobCard`
        );
        const fields = data.data;

        setFormFields((formFields) => {
          const newFormFields = { ...formFields };
          for (const [key, value] of Object.entries(fields)) {
            (newFormFields as any)[key].items = value;
          }
          return newFormFields;
        });
      } catch (e) {
        console.error(e);
      }
    };
    getMultiSelectValues();
  }, []);

  const initialFormData: FormDataType = {
    driveName: "",
    rolesOffered: [],
    selectionProcess: [],
    minCtcOffered: NaN,
    maxCtcOffered: NaN,
    salaryComments: "",
    noOfPositions: "",
    location: [],
    otherCodingSkills: [],
    jd: "", // alag
    resources: [""], // alag
    deadline: dayjs(), // alag
    companyDetails: companyDataState.id,
    isBondOffering: false,
    isHidden: false,
    opsPOC: "",
    reachoutTeamPOC: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  // Custom Question State Management
  const sampleCustomQuestionData: CustomQuestion[] = [
    {
      questionType: QuestionType.Checkbox,
      questionString: "",
      answerOptions: [""],
      jobCard: "",
    },
  ];

  const templateCustomQuestion: any[] = [];

  const [customQuestionData, setCustomQuestionData] = useState(
    templateCustomQuestion
  );

  // fetch already saved data if any
  useEffect(() => {
    const prefillCompanyDetails = async () => {
      const jobDetailsData = jobCardDataState;
      setFormData((oldFormData) => {
        const newFormData = { ...oldFormData };
        newFormData.minCtcOffered =
          jobDetailsData.minCtcOffered != undefined
            ? jobDetailsData.minCtcOffered
            : newFormData.minCtcOffered;
        newFormData.isBondOffering =
          jobDetailsData.isBondOffering != undefined
            ? jobDetailsData.isBondOffering
            : newFormData.isBondOffering;
        newFormData.isHidden =
          jobDetailsData.isHidden != undefined
            ? jobDetailsData.isHidden
            : newFormData.isHidden;
        newFormData.maxCtcOffered =
          jobDetailsData.maxCtcOffered != undefined
            ? jobDetailsData.maxCtcOffered
            : newFormData.maxCtcOffered;
        newFormData.deadline =
          jobDetailsData.deadline != undefined
            ? jobDetailsData.deadline
            : newFormData.deadline;
        newFormData.jd =
          jobDetailsData.jd != undefined ? jobDetailsData.jd : newFormData.jd;
        newFormData.location =
          jobDetailsData.location != undefined
            ? jobDetailsData.location
            : newFormData.location;
        newFormData.noOfPositions =
          jobDetailsData.noOfPositions != undefined
            ? jobDetailsData.noOfPositions
            : newFormData.noOfPositions;
        newFormData.otherCodingSkills =
          jobDetailsData.otherCodingSkills != undefined
            ? jobDetailsData.otherCodingSkills
            : newFormData.otherCodingSkills;
        newFormData.resources =
          jobDetailsData.resources != undefined
            ? jobDetailsData.resources
            : newFormData.resources;
        newFormData.rolesOffered =
          jobDetailsData.rolesOffered != undefined
            ? jobDetailsData.rolesOffered
            : newFormData.rolesOffered;
        newFormData.driveName =
          jobDetailsData.driveName != undefined
            ? jobDetailsData.driveName
            : newFormData.driveName;
        newFormData.salaryComments =
          jobDetailsData.salaryComments != undefined
            ? jobDetailsData.salaryComments
            : newFormData.salaryComments;
        newFormData.selectionProcess =
          jobDetailsData.selectionProcess != undefined
            ? jobDetailsData.selectionProcess
            : newFormData.selectionProcess;
        newFormData.opsPOC =
          jobDetailsData.opsPOC != undefined
            ? jobDetailsData.opsPOC
            : newFormData.opsPOC;
        newFormData.reachoutTeamPOC =
          jobDetailsData.reachoutTeamPOC != undefined
            ? jobDetailsData.reachoutTeamPOC
            : newFormData.reachoutTeamPOC;
        return newFormData;
      });
      if (jobDetailsData.customQuestions != undefined) {
        setCustomQuestionData(jobDetailsData.customQuestions);
      }
    };

    if (Object.keys(jobCardDataState).length != 0) {
      prefillCompanyDetails();
    }
  }, [jobCardDataState]);

  // Update Form Data on Input Change
  const updateFormData = (fieldId: string, fieldValue: string) => {
    setFormData({
      ...formData,
      [fieldId]: fieldValue,
    });
  };

  // Add Resource
  const addResource = () => {
    setFormData((formData) => {
      const newFormData = { ...formData };
      newFormData.resources.push("");
      return newFormData;
    });
  };

  // Remove Resource
  const removeResource = (resourceIdx: number) => {
    setFormData((formData) => {
      const newFormData = { ...formData };
      newFormData.resources.splice(resourceIdx, 1);
      return newFormData;
    });
  };

  const backToCompanyForm = async () => {
    try {
      if (jobCardDataState?.id) {
        dispatch(
          updateJobCardDataToState({ ...formData, id: jobCardDataState.id })
        );
      } else {
        dispatch(updateJobCardDataToState({ ...formData }));
      }

      navigate("/companyForm");
    } catch (e) {
      throw e;
    }
  };

  // Update Resource
  const updateResource = (resourceIdx: number, resourceValue: string) => {
    setFormData((formData) => {
      const newFormData = { ...formData };
      newFormData.resources[resourceIdx] = resourceValue;
      return newFormData;
    });
  };

  // Handle Form Submit
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setLoading(true);
    const postData: FormDataType & { tracks?: string[] } = { ...formData };

    postData.isBondOffering = postData.isBondOffering;
    postData.isHidden = postData.isHidden;
    postData.opsPOC = postData.opsPOC === "None" ? "" : postData.opsPOC;
    postData.tracks = tracks;
    // post job details at job-card/jobCard
    let jcdata: any = {};
    try {
      if (!jobCardDataState.id) {
        postData.companyDetails = companyDataState.id;
        jcdata = (await api.postJobCardData(postData)).data;
      } else {
        postData.companyDetails = companyDataState.id;
        jcdata = (
          await api.postJobCardData({
            ...postData,
            id: jobCardDataState.id,
          })
        ).data;
      }
    } catch (e) {
      console.error(e);
    }

    customQuestionData.forEach((data) => {
      data.jobCard = jcdata.data.id;
    });

    const postCustomQuestions = async () => {
      const cq = await axios.post(
        `${rootEndpoint}/job-card/questions`,
        customQuestionData
      );
      dispatch(
        postJobCardData({ ...jcdata.data, customQuestions: cq.data.data })
      );
    };
    await postCustomQuestions();
    setLoading(false);
    dispatch(updateJobCardEligibilityToState(eligibilityJobCardDataState));
    navigate("/eligibilityForm");
  };
  return (
    <div className="placement-container">
      <button onClick={backToCompanyForm}>Back</button>
      <h1 className="heading">Job Details Form</h1>

      {/* Form Begin */}
      <form onSubmit={handleSubmit}>
        {/* Basic Fields Begin */}
        <div className="form-fields-container">
          {Object.entries(formFields).map(([id, field], idx): ReactElement => {
            if (field.type === "select")
              return (
                <SelectField
                  key={idx}
                  id={id}
                  {...field}
                  updateFormData={updateFormData}
                  formData={formData}
                  required={field.required}
                />
              );
            else if (field.type === "multiselect")
              return (
                <MultiSelectField
                  key={idx}
                  id={id}
                  {...field}
                  updateFormData={updateFormData}
                  formData={formData}
                />
              );
            else if (field.type == "checkbox")
              return (
                <CheckBox
                  key={idx}
                  id={id}
                  {...field}
                  updateFormData={updateFormData}
                  formData={formData}
                  required={field.required}
                />
              );
            else
              return (
                <TextField
                  key={idx}
                  id={id}
                  {...field}
                  updateFormData={updateFormData}
                  formData={formData}
                  required={field.required}
                />
              );
          })}
        </div>

        <div style={{ marginBottom: "2rem" }}>
          <h3>Job Description</h3>
          <ReactQuill
            theme="snow"
            value={formData.jd}
            className="rich-text-area"
            onChange={(value) => {
              setFormData((f) => {
                const newFormData = { ...f };
                newFormData.jd = value;
                return newFormData;
              });
            }}
          />

          {/* Resource Links -> array of links */}
          <h3>Resource Links</h3>
          {formData.resources.length === 0 && (
            <Button className="add-button" onClick={addResource}>
              <AddIcon />
            </Button>
          )}
          {formData.resources.map((resource, idx) => (
            <div className="resource-row" key={idx}>
              <MaterialTextField
                required
                id="outlined-basic"
                label="Resource Link"
                variant="outlined"
                style={{ width: "100%" }}
                name={"resources" + idx}
                value={resource}
                onChange={(e) => updateResource(idx, e.target.value)}
              />
              <Button className="add-button" onClick={addResource}>
                <AddIcon />
              </Button>
              <Button
                className="delete-button"
                onClick={() => {
                  removeResource(idx);
                }}
              >
                <CloseIcon />
              </Button>
            </div>
          ))}

          {/* Deadline -> date picker */}
          <h3>Deadline</h3>
          <div>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label='Set Deadline'
                value={formData.deadline}
                onChange={(newDate) => {
                  setFormData({ ...formData, deadline: newDate });
                }}
                renderInput={(params) => <MaterialTextField {...params} />}
              />
            </LocalizationProvider> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => <MaterialTextField {...props} />}
                label="DateTimePicker"
                value={formData.deadline}
                onChange={(newDate) => {
                  setFormData({ ...formData, deadline: newDate });
                }}
              />
            </LocalizationProvider>
          </div>

          {/* Custom Questions -> Array of questions */}
          <h3 style={{ marginTop: "2rem", marginBottom: "0.5rem" }}>
            Custom Questions
          </h3>
          <CustomQuestions
            customQuestionData={customQuestionData}
            setCustomQuestionData={setCustomQuestionData}
            sampleCustomQuestionData={sampleCustomQuestionData}
          />
        </div>

        <Button
          type="submit"
          variant="contained"
          className="btn"
          disabled={loading}
        >
          {loading ? "Loading..." : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default JobDetailsForm;
