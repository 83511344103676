const initialState=[];
export default (batchManagers = initialState , action) =>{
    switch(action.type){
        case 'GET_BATCH_MANAGERS':
            return action.payload;

        
        default :
            return batchManagers;
    }
}