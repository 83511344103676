import { CircularProgress, Button as RootButton } from "@mui/material";
import { CSSProperties, ReactNode, type FC } from "react";

type ButtonPropsType = {
  loading?: boolean;
  onClick: () => void;
  style?: CSSProperties;
  title: ReactNode;
  disabled?: boolean;
  children?: ReactNode;
};

export const Button: FC<ButtonPropsType> = ({
  onClick,
  disabled,
  loading,
  style,
  title,
  children,
}) => {
  return (
    <RootButton
      disabled={disabled || loading}
      onClick={onClick}
      style={style}
      variant="contained"
    >
      {loading ? (
        <div style={{
          padding: "4px 24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <CircularProgress style={{ color: "white" }} size={24} />
          </div>
      ) : title}
      {children}
    </RootButton>
  );
};
