const initialState = [];

export default (fullDataState = initialState, action) => {
  switch (action.type) {
    case "GETFULLDATAOFSTUDENTS":
      return action.payload;
    case "UPDATE_DATA_OF_STUDENT":
      let newFullDataState = fullDataState.map((student) =>
        student.studentId === action.payload.studentId
          ? action.payload
          : student
      );
      return newFullDataState;

    default:
      return fullDataState;
  }
};
