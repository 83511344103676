const initialState=[];
export default (contestsDataState = initialState , action) =>{
    switch(action.type){
        case 'GET_CONTESTS_OF_A_STUDENT':
            return action.payload;
            case 'REMOVE_CONTESTS_OF_A_STUDENT':
                return [];

        default :
            return contestsDataState;
    }
}