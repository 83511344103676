const initialState='';
export default (gymDataState = initialState,action) => {
    switch(action.type){
        case 'GET_GYM_COUNT':
            return action.payload;
        case 'REMOVE_GYM_COUNT':
            return null;
        default : 
        return gymDataState;
    }
}