import { Button, TextField } from "@mui/material";
import IndividualMockScore from "./IndividualMockScore";
import { MockScoreBlockType } from "./EligibilityForm";

const MockScoreBlock = ({
  blockIdx,
  data,
  addMockScoreBlock,
  addIndividualMockScore,
  updateIndividualMockScore,
  updateMockScoreBlockDescription,
  sessionTypes,
}: {
  blockIdx: number;
  data: MockScoreBlockType;
  addMockScoreBlock: () => void;
  addIndividualMockScore: () => void;
  updateIndividualMockScore: (
    blockIdx: number,
    individualIdx: number,
    element: string,
    value: string,
    type: string
  ) => void;
  updateMockScoreBlockDescription: (blockIdx: number, value: string) => void;
  sessionTypes: string[];
}) => {
  return (
    <div className="mock-score-block">
      <TextField
        required
        name={blockIdx + "-description"}
        label="Description"
        style={{ width: "60%" }}
        value={data.description}
        onChange={(e) => {
          updateMockScoreBlockDescription(blockIdx, e.target.value);
        }}
      />

      {data.statement.map((individualMockScoreData, idx) => (
        <IndividualMockScore
          key={idx}
          idx={idx}
          blockIdx={blockIdx}
          data={individualMockScoreData}
          addIndividualMockScore={addIndividualMockScore}
          updateIndividualMockScore={updateIndividualMockScore}
          sessionTypes={sessionTypes}
        />
      ))}

      <Button
        className="logical-operator and"
        style={{ width: "140px" }}
        onClick={addMockScoreBlock}
      >
        AND
      </Button>
    </div>
  );
};

export default MockScoreBlock;
