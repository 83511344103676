import * as api from "../api";

export const postCompanyData = (formData) => async (dispatch) => {
  try {
    const { data } = await api.postCompanyData(formData);
    dispatch({
      type: "POSTCOMPANYDATA",
      payload: { data: { ...formData, ...data.data } },
    });
  } catch (error) {
    console.error(error);
  }
};

export const postJobCardData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "POSTJOBCARDDATA", payload: formData });
  } catch (error) {
    console.error(error);
  }
};

export const updateJobCardDataToState = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "POSTJOBCARDDATA", payload: formData });
  } catch (error) {
    console.error(error);
  }
};

export const updateJobCardEligibilityToState =
  (formData) => async (dispatch) => {
    try {
      dispatch({ type: "POSTJOBCARDELIGIBILITYDATA", payload: formData });
    } catch (error) {
      console.error(error);
    }
  };

export const emptyJobCardStateAndCompanyState = () => (dispatch) => {
  dispatch({ type: "EMPTYSTATE" });
};

export const editJobCardDetails = (data) => (dispatch) => {
  dispatch({ type: "SETEDITJOBCARDDETAILS", payload: data });
};

export const getSessionIds = (data) => (dispatch) => {
  dispatch({ type: "SETSESSIONIDS", payload: data });
};

export const setTracks = (data) => (dispatch) => {
  dispatch({ type: "SET_SELECTED_TRACKS", payload: data });
};

export const setAllTracks = (data) => (dispatch) => {
  dispatch({ type: "SET_ALL_TRACKS", payload: data });
};
