import { combineReducers } from "redux";
import views from "./views";
import fullData from "./fullData";
import followUps from "./followUps";
import studentNotes from "./studentNotes";
import dailyFollowUp from "./dailyFollowUp";
import studentCourses from "./studentCourses";
import studentMocks from "./studentMocks";
import auth from "./auth";
import view from "./view";
import batchManagers from "./batchManagers";
import studentContestData from "./studentContestData";
import adminData from "./adminData";
import kpi from "./kpi";
import gymCount from "./gymCount";
import jobCard from "./jobCard";
import studentContests from "./studentContests";
import { withReduxStateSync } from 'redux-state-sync'
export default withReduxStateSync(combineReducers({
  views,
  fullData,
  followUps,
  dailyFollowUp,
  studentCourses,
  studentMocks,
  studentContests,
  auth,
  view,
  studentNotes,
  studentContestData,
  batchManagers,
  adminData,
  kpi,
  gymCount,
  jobCard,
}));
