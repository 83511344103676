const initialState='';
export default (contestDataState = initialState,action) => {
    switch(action.type){
        case 'GET_CONTEST_DATA':
            return action.payload;
        case 'REMOVE_CONTEST_DATA':
            return null;
        default : 
        return contestDataState;
    }
}