import Container from "@mui/material/Container";
import { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppDispatch, RootState } from ".";
import Navbar from "../src/Components/Navbar/Navbar";
import { getFullDataOfStudents } from "./actions/fullDataAction";
import "./App.css";
import Authenticate from "./Components/Authenticate/Authenticate";
import CompanyForm from "./Components/CompanyForm/CompanyForm";
import EligibilityForm from "./Components/EligibilityForm/EligibilityForm";
import JobDetailsForm from "./Components/JobDetailsForm/JobDetailsForm";
import ChangeStage from "./Components/PlacementDashboard/ChangeStage";
import JobCardDetails from "./Components/PlacementDashboard/JobCardDetails";
import JobCardGrid from "./Components/PlacementDashboard/JobCardGrid";
import PlacementDashboard from "./Components/PlacementDashboard/PlacementDashboard";
import { PLACEMENT_CRM_TOKEN_NAME } from "./config";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const authenticationRoute = "/authenticate";

const App = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (localStorage.getItem("BMId")) dispatch(getFullDataOfStudents());
  }, [dispatch]);

  const token = localStorage.getItem(PLACEMENT_CRM_TOKEN_NAME);
  if (!token && window.location.pathname !== authenticationRoute) {
    return <h1>Please login using Appsmith</h1>;
  }

  return (
    <BrowserRouter>
      <Navbar />
      <Container>
        <Routes>
          <Route path="/" element={<PlacementDashboard />} />
          <Route path={authenticationRoute} element={<Authenticate />} />
          <Route path="/eligibilityForm" element={<EligibilityForm />} />
          <Route path="/companyForm" element={<CompanyForm />} />
          <Route path="/jobDetailsForm" element={<JobDetailsForm />} />
          <Route path="/studentEmails" element={<ChangeStage />} />
          <Route path="/jobCardGrid" element={<JobCardGrid />} />
          <Route path="/jobCardDetails" element={<JobCardDetails />} />
        </Routes>
      </Container>
    </BrowserRouter>
  );
};

export default App;
