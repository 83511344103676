const initialState=[];
export default (notes = initialState , action) =>{
    switch(action.type){
        case 'GET_NOTES':
            return action.payload;

        case 'ADD_NOTE':
            let notesData= [...notes]; 
            notesData.unshift(action.payload);
            return notesData ;
        case 'REMOVE_NOTES':
            return [];
        default :
            return notes;
    }
}