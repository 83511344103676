import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { getHoldStatus, getStages, placementCrmGridData } from "../../api";
import { rootEndpoint } from "../../config/endpoint";
import "./ChangeStage.css";
import ChangeStageTable from "./ChangeStageTable";
import StageChangeModal from "./components/Modal";

const ChangeStage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isHoldStatus, setIsHoldStatus] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const jobCardID = location.search.split("=")[1];

  useEffect(() => {
    getHoldStatus(jobCardID)
      .then((response) => {
        setIsHoldStatus(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const csvDownload = () => {
    const getEligibleStudents = async () => {
      // post request to job-card/eligibility
      const req = await axios.get(
        `${rootEndpoint}/job-card/appliedStudentDataFromJob?jobCardId=${jobCardID}`
      );
      const items = req.data.data;
      const replacer = (key: string, value: string) =>
        value === null ? "" : value; // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","), // header row first
        ...items.map((row: any) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");

      const exportedFilename = "applied-students.csv";

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };
    getEligibleStudents();
  };

  const getCountOfAppliedStudents = () => {
    const getEligibleStudents = async () => {
      // post request to job-card/eligibility
      const req = await axios.get(
        `${rootEndpoint}/job-card/appliedStudentsCount?jobCardId=${jobCardID}`
      );
      const count = req.data;
      return count;
    };

    getEligibleStudents()
      .then((x) => {
        alert(x.message + " : " + x.data.count);
      })
      .catch((e) => {
        alert(e);
      });
  };

  const changeHoldStatus = () => {
    if (!isHoldStatus && !showHoldReasonPopup) {
      setShowHoldReasonPopup(true);
    } else {
      updateHoldStatus();
    }
  };

  const updateHoldStatus = async () => {
    try {
      const response = await axios.post(
        `${rootEndpoint}/job-card/changeHoldStatus`,
        {
          jobCardId: jobCardID,
          holdReason: holdReason,
          holdStatus: !isHoldStatus,
        }
      );
    } catch (error) {
      console.error("Error:", error);
    }
    setShowHoldReasonPopup(false);
    setIsHoldStatus(!isHoldStatus);
  };

  const [showHoldReasonPopup, setShowHoldReasonPopup] = useState(false);
  const [showDashboardPopup, setShowDashboardPopup] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const [showForm2, setShowForm2] = useState(false);

  const [endDateForm, setEndDateForm] = useState(false);
  const [endAtDate, setEndAtDate] = useState("");

  const [stage, setStage] = useState([]);

  const [stageName, setStageName] = useState("");
  const [studentDetails, setStudentDetails] = useState<any>([]);

  const [holdReason, setHoldReason] = useState("");

  async function saveEndAt() {
    const data = {
      jobCardId: jobCardID,
      endAt: endAtDate,
    };
    const sendData = async (data: any) => {
      const req = await axios.post(
        `${rootEndpoint}/job-card/setJobEndAt`,
        data
      );
      window.alert("saved");
    };
    sendData(data);
  }

  async function ValidateEmail() {
    var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let emails: any = document.getElementById("inputElement1");
    emails.value = emails.value.replace(/ /g, "");

    const emailArray = emails.value.split(/[\s,]/);
    const notes = (document.getElementById("inputElement2") as HTMLInputElement)
      .value;

    for (var i = 0; i < emailArray.length; i++) {
      if (emailArray[i].match(mailFormat) == null) {
        alert("You have entered an invalid email address!");
        document.getElementById("inputElement1")?.focus();
        return false;
      }
    }

    const data = {
      emails: emailArray,
      stage: stageName,
      jobCardId: jobCardID,
      notes: notes,
    };

    const sendData = async (data: any) => {
      const req = await axios.post(
        `${rootEndpoint}/job-card/updateJobCardStage`,
        data
      );
      const items = req.data;
      const replacer = (key: string, value: string) =>
        value === null ? "" : value; // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","), // header row first
        ...items.map((row: any) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");

      const exportedFilename = "stage-change.csv";

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };
    sendData(data);
  }

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(
        `${rootEndpoint}/job-card/job-card-stages-data/${jobCardID}`
      );
      setDashboardData(response.data);
      if (response.data) {
        setShowDashboardPopup(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStages(jobCardID)
      .then((response) => {
        setStage(response.data.data);
        if (response.data.data.length > 0) {
          setStageName(response.data.data[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState<any>([]);
  const [rowsLength, setRowsLength] = useState<any>();

  useEffect(() => {
    placementCrmGridData(jobCardID).then((response) => {
      const res1 = response.data.data.data;
      setStudentDetails(res1);
    });
  }, []);
  useEffect(() => {
    try {
      let keyArray1 = [];
      let keyArray2 = [];
      for (let item in studentDetails[0]) {
        if (
          item === "email" ||
          item === "firstName" ||
          item === "lastName" ||
          item === "phoneNumber" ||
          item === "resume" ||
          item === "batch_manager_email" ||
          item === "currentStage" ||
          item === "rejected_status"
        ) {
          keyArray1.push(item);
        } else {
          keyArray2.push(item);
        }
      }
      let keyArray = [...keyArray1, ...keyArray2];

      setCols([
        { field: "id", headerName: "ID", width: 90 },
        ...keyArray?.map((item: any) => {
          return {
            field: item,
            headerName: item?.toUpperCase(),
            width: 250,
            editable: true,
          };
        }),
      ]);
    } catch (error) {
      console.error(error);
    }
  }, [studentDetails]);

  useEffect(() => {
    try {
      let temp: any = [];
      studentDetails?.forEach((item1: any, index: number) => {
        temp.push({ id: index, ...item1 });
      });
      setRows(temp);
      setRowsLength(temp.length);
    } catch (error) {
      console.error(error);
    }
  }, [cols]);

  return (
    <div className="change-state-section">
      <div className="mainButtons">
        <button
          onClick={() => {
            navigate("/");
          }}
        >
          Back
        </button>
        <br />
        <button onClick={csvDownload}>Applied Students Data</button>
        <button onClick={getCountOfAppliedStudents}>
          Count of Applied Students
        </button>
        <button onClick={changeHoldStatus}>
          {!isHoldStatus ? "Hold This Drive" : "Unhold This Drive"}
        </button>
        {showHoldReasonPopup && (
          <div className="popup">
            <div className="popup-content">
              <h2>Enter Hold Reason</h2>
              <textarea
                id="holdReasonInput"
                value={holdReason}
                onChange={(event) => setHoldReason(event.target.value)}
              ></textarea>
              <button onClick={() => setShowHoldReasonPopup(false)}>
                Cancel
              </button>
              <button onClick={changeHoldStatus}>Hold This Drive</button>
            </div>
          </div>
        )}

        {selectedRows?.length > 0 ? (
          <button onClick={() => setShowForm(!showForm)}>
            Change Stage of Selected Students
          </button>
        ) : null}
        <button onClick={() => setShowForm2(!showForm2)}>
          Change Stage of Students
        </button>
        <button onClick={() => setEndDateForm(!endDateForm)}>
          Add end date
        </button>
        <button onClick={fetchDashboardData}>Show Stage Wise Data</button>
      </div>
      {showForm2 ? (
        <StageChangeModal
          showForm={showForm2}
          setShowForm={setShowForm2}
          stage={stage}
          stageName={stageName}
          setStageName={setStageName}
          ValidateEmail={ValidateEmail}
          selectedEmails={selectedRows?.map((item: any) => " " + item.email)}
          emailToggle={true}
        />
      ) : null}
      {showForm ? (
        <StageChangeModal
          showForm={showForm}
          setShowForm={setShowForm}
          stage={stage}
          setStageName={setStageName}
          stageName={stageName}
          ValidateEmail={ValidateEmail}
          selectedEmails={selectedRows?.map((item: any) => " " + item.email)}
          emailToggle={false}
        />
      ) : null}
      {endDateForm ? (
        <div className="changeStage" id="changes">
          <form
            name="form1"
            className="change"
            id="stage"
            action="#"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="date"
              id="endAtDate"
              value={endAtDate}
              onChange={(event) => setEndAtDate(event.target.value)}
            />
            <button className="submitBtn" onClick={saveEndAt}>
              Submit
            </button>
          </form>
        </div>
      ) : null}

      {showDashboardPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Data</h2>
            {Object.entries(dashboardData).map(([stage, data]) => {
              if (
                typeof data === "object" &&
                data["not-rejected"] !== undefined
              ) {
                return null;
              } else {
                return (
                  <h4>
                    {stage} - {data}
                  </h4>
                );
              }
            })}
            <div className="tables-container">
              {/* Table for "not-rejected" */}
              <table className="stage-table">
                <thead>
                  <tr>
                    <th>Stage (Ongoing)</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(dashboardData).map(([stage, data]) => {
                    if (
                      typeof data === "object" &&
                      data["not-rejected"] !== undefined
                    ) {
                      return (
                        <tr key={`${stage}-not-rejected`}>
                          <td>{stage}</td>
                          <td>{data["not-rejected"]}</td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </table>

              {/* Table for "rejected" */}
              <table className="stage-table">
                <thead>
                  <tr>
                    <th>Stage Of Rejection</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(dashboardData).map(([stage, data]) => {
                    if (
                      typeof data === "object" &&
                      data["rejected"] !== undefined
                    ) {
                      return (
                        <tr key={`${stage}-rejected`}>
                          <td>{stage}</td>
                          <td>{data["rejected"]}</td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </table>
            </div>

            <button onClick={() => setShowDashboardPopup(false)}>Close</button>
          </div>
        </div>
      )}

      <ChangeStageTable
        rows={rows}
        rowsLength={rowsLength}
        columns={cols}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};
export default ChangeStage;
