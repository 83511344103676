import { Button } from "@mui/material";
import { deleteJobCardCustomQuestionApi } from "../../api";
import IndividualCustomQuestion from "./IndividualCustomQuestion";
import { CustomQuestion, QuestionType } from "./JobDetailsForm";

interface CustomQuestionProps {
  customQuestionData: CustomQuestion[];
  setCustomQuestionData: React.Dispatch<React.SetStateAction<CustomQuestion[]>>;
  sampleCustomQuestionData: CustomQuestion[];
}

const CustomQuestions = ({
  customQuestionData,
  setCustomQuestionData,
  sampleCustomQuestionData,
}: CustomQuestionProps) => {
  const addCustomQuestion = () => {
    setCustomQuestionData((currCustomQuestionData) => {
      const newCustomQuestionData = [...currCustomQuestionData];
      newCustomQuestionData.push({ ...sampleCustomQuestionData[0] });
      return newCustomQuestionData;
    });
  };

  // remove custom question
  const removeCustomQuestion = async (customQuestionIndex: number) => {
    const questionData = customQuestionData[customQuestionIndex];
    setCustomQuestionData((currCustomQuestionData) => {
      const newCustomQuestionData = [...currCustomQuestionData];
      newCustomQuestionData.splice(customQuestionIndex, 1);
      return newCustomQuestionData;
    });

    if (questionData.id) await deleteJobCardCustomQuestionApi(questionData.id);
  };

  // update custom question (type field)
  const updateCustomQuestionTypeField = (
    customQuestionIndex: number,
    value: QuestionType
  ) => {
    setCustomQuestionData((currCustomQuestionData) => {
      const newCustomQuestionData = [...currCustomQuestionData];
      newCustomQuestionData[customQuestionIndex].questionType = value;
      if (value === QuestionType.Checkbox || value === QuestionType.Radio)
        newCustomQuestionData[customQuestionIndex].answerOptions = [""];
      else
        newCustomQuestionData[customQuestionIndex].answerOptions =
          [] as string[];
      return newCustomQuestionData;
    });
  };

  // update custom question (question field)
  const updateCustomQuestionQuestionField = (
    customQuestionIndex: number,
    value: string
  ) => {
    setCustomQuestionData((currCustomQuestionData) => {
      const newCustomQuestionData = [...currCustomQuestionData];
      newCustomQuestionData[customQuestionIndex].questionString = value;
      return newCustomQuestionData;
    });
  };

  const addOption = (customQuestionIndex: number) => {
    setCustomQuestionData((currCustomQuestionData) => {
      const newCustomQuestionData = [...currCustomQuestionData];
      newCustomQuestionData[customQuestionIndex].answerOptions?.push("");
      return newCustomQuestionData;
    });
  };

  const removeOption = (customQuestionIndex: number, optionIndex: number) => {
    setCustomQuestionData((currCustomQuestionData) => {
      const newCustomQuestionData = [...currCustomQuestionData];
      newCustomQuestionData[customQuestionIndex].answerOptions.splice(
        optionIndex,
        1
      );
      if (newCustomQuestionData[customQuestionIndex].answerOptions.length === 0)
        newCustomQuestionData[customQuestionIndex].answerOptions = [""];
      return newCustomQuestionData;
    });
  };

  const updateOption = (
    customQuestionIndex: number,
    optionIndex: number,
    value: string
  ) => {
    setCustomQuestionData((currCustomQuestionData) => {
      const newCustomQuestionData = [...currCustomQuestionData];
      newCustomQuestionData[customQuestionIndex].answerOptions[optionIndex] =
        value;
      return newCustomQuestionData;
    });
  };

  const updateCustomQuestionCorrectAnswer = (
    customQuestionIndex: number,
    value: string
  ) => {
    setCustomQuestionData((currCustomQuestionData) => {
      const newCustomQuestionData = [...currCustomQuestionData];
      newCustomQuestionData[customQuestionIndex].correctAnswer = value;
      return newCustomQuestionData;
    });
  };

  return (
    <div className="custom-questions-container">
      {customQuestionData.length === 0 && (
        <Button
          className="add-custom-question-button"
          style={{ marginLeft: "-2.2rem", marginTop: "1rem" }}
          onClick={addCustomQuestion}
        >
          Add Question
        </Button>
      )}
      {customQuestionData.map((customQuestion, idx) => (
        <IndividualCustomQuestion
          key={idx}
          idx={idx}
          data={customQuestion}
          addCustomQuestion={addCustomQuestion}
          removeCustomQuestion={removeCustomQuestion}
          updateCustomQuestionTypeField={updateCustomQuestionTypeField}
          updateCustomQuestionQuestionField={updateCustomQuestionQuestionField}
          addOption={addOption}
          removeOption={removeOption}
          updateOption={updateOption}
          updateCustomQuestionCorrectAnswer={updateCustomQuestionCorrectAnswer}
        />
      ))}
    </div>
  );
};

export default CustomQuestions;
