import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { rootEndpoint } from "../../config/endpoint";

const JobCardDetails = () => {
  const navigate = useNavigate();
  const [jobCards, setJobCards] = useState([]);
  const [selectedJobCardId, setSelectedJobCardId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await axios.get(`${rootEndpoint}/job-card`);
        const data = req.data.data;
        setJobCards(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  //@ts-ignore
  const handleDropdownChange = (event) => {
    setSelectedJobCardId(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    navigate(`/JobCardGrid?jobCardId=${selectedJobCardId}`);
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <h1>Select JobCard</h1>
      <form onSubmit={handleSubmit}>
        <select
          id="jobCardDropdown"
          value={selectedJobCardId}
          onChange={handleDropdownChange}
          style={{
            padding: "10px",
            fontSize: "16px",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
        >
          <option value="">Select a job card</option>
          {jobCards.map((jobCard: any) => (
            <option key={jobCard.id} value={jobCard.jobCardId}>
              {jobCard.jobCard}
            </option>
          ))}
        </select>
        <button
          type="submit"
          style={{
            marginTop: "10px",
            padding: "10px",
            fontSize: "16px",
            borderRadius: "4px",
            backgroundColor: "blue",
            color: "white",
            border: "none",
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default JobCardDetails;
