import axios from "axios";
import { PLACEMENT_CRM_TOKEN_NAME } from "./config";
import { rootEndpoint } from "./config/endpoint";

axios.defaults.headers.common[PLACEMENT_CRM_TOKEN_NAME] =
  localStorage.getItem(PLACEMENT_CRM_TOKEN_NAME) ?? "";

export const fetchViews = () => {
  const bMId = localStorage.getItem("BMId");
  return axios.get(
    `${rootEndpoint}/batchManagerDash/getBatchManagerViews/${bMId}`
  );
};

export const getFullDataOfStudents = () => {
  const bMId = localStorage.getItem("BMId");
  return axios.get(
    `${rootEndpoint}/batchManagerDash/getFullDataOfStudent/${bMId}`
  );
};

export const getBatchManagers = () => {
  return axios.get(`${rootEndpoint}/batchManagerDash/getBatchManagers`);
};

export const getFullDataOfStudentsOfEachManagerForAdminView = async (
  bMId: any
) => {
  return await axios.get(
    `${rootEndpoint}/batchManagerDash/getFullDataOfStudent/${bMId}`
  );
};

export const postCompanyData = async (data: any) => {
  return await axios.post(`${rootEndpoint}/job-card/companies`, data);
};

export const postJobCardData = async (data: any) => {
  return await axios.post(`${rootEndpoint}/job-card/jobCard`, data);
};

export const getStages = async (data: any) => {
  return await axios.get(
    `${rootEndpoint}/job-card/getStagesFromJobCardId?jobCardId=${data}`
  );
};

export const getHoldStatus = async (data: any) => {
  return await axios.get(
    `${rootEndpoint}/job-card/getHoldStatusFromJobCardId?jobCardId=${data}`
  );
};

export const publishJobCard = async (data: any) => {
  return await axios.post(`${rootEndpoint}/job-card/publishJobCard`, data);
};

export const getMainPortalJobCard = async (jobCardId: any) => {
  return await axios.get(
    `${rootEndpoint}/job-card/getMainPortalJobCard?jobCardId=${jobCardId}`
  );
};

export const placementCrmGridData = async (jobCardId: any) => {
  return await axios.get(
    `${rootEndpoint}/job-card/placementCrmGridData/${jobCardId}`
  );
};

export const fetchJobCardsApi = async (cursorId: any, searchText: any) => {
  return await axios.get(
    `${rootEndpoint}/job-card/jobCardValuesInternalTool?cursorId=${cursorId}&searchText=${searchText}`
  );
};

export const deleteJobCardCustomQuestionApi = async (id: any) => {
  return await axios.post(`${rootEndpoint}/job-card/delete-custom-question`, {
    draftQuestionId: id,
  });
};
