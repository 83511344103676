import {
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import axios from "axios";
import {
  FormEventHandler,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../App";
import {
  postCompanyData,
  setAllTracks,
  setTracks,
} from "../../actions/jobCard";
import { rootEndpoint } from "../../config/endpoint";
import SelectField from "./formElements/SelectField";
import TextField from "./formElements/TextField";
import "./styles.css";

interface CompanyDataType {
  companyName: string;
  hrName: string;
  hrEmailId: string;
  hrContactNumber: string;
}

export interface FormDataType extends CompanyDataType {
  companyNameSelect: string;
  hrEmailIdSelect: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface FormFieldProps {
  id: string;
  label: string;
  type: string;
  items?: Array<string> | Array<{ label: string; value: string }>;
  updateFormData: (fieldId: string, fieldValue: string) => void;
  formData: FormDataType;
  disabled?: Boolean;
}

const FormFields = {
  companyNameHeading: {
    label: "Company Name",
    type: "heading",
  },
  companyNameSelect: {
    label: "Select Company Name",
    type: "select",
    items: ["Other"],
    disabled: false,
  },
  companyName: {
    label: "Company Name",
    type: "text",
    disabled: true,
  },
  hrEmailIdEHeading: {
    label: "HR Email ID",
    type: "heading",
  },
  hrEmailIdSelect: {
    label: "Select HR Email Id",
    type: "select",
    items: ["Other"],
    disabled: true,
  },
  hrEmailId: {
    label: "HR Email Id",
    type: "text",
    disabled: true,
  },
  hrDetailsHeading: {
    label: "HR Details",
    type: "heading",
  },
  hrName: {
    label: "HR Name",
    type: "text",
    disabled: false,
  },
  hrContactNumber: {
    label: "HR Contact Number",
    type: "text",
    disabled: false,
  },
};

const CompanyForm = () => {
  const initialFormData: FormDataType = {
    companyNameSelect: "Other",
    companyName: "",
    hrEmailIdSelect: "Other",
    hrEmailId: "",
    hrName: "",
    hrContactNumber: "",
  };

  const [formData, setFormData] = useState<FormDataType>(initialFormData);
  const [formFields, setFormFields] = useState(FormFields);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {
    companyData: companyDataState,
    tracks: selectedTracks,
    allTracks,
  } = useSelector((state) => (state as any).jobCard as any);

  useEffect(() => {
    const getMultiSelectValues = async () => {
      try {
        const [{ data: companiesData }, { data: tracksData }] =
          await Promise.all([
            axios.get(`${rootEndpoint}/job-card/getCompanies`),
            axios.get(`${rootEndpoint}/job-card/tracksForInternalTool`),
          ]);
        const companies = companiesData.data;

        setFormFields((formFields) => {
          const newFormFields = { ...formFields };
          newFormFields.companyNameSelect.items = [];
          companies.forEach((company: { companyName: string }) => {
            newFormFields.companyNameSelect.items.push(company.companyName);
          });
          newFormFields.companyNameSelect.items.push("Other");
          return newFormFields;
        });

        dispatch(setAllTracks(tracksData));
      } catch (e) {
        console.error(e);
      }
    };
    getMultiSelectValues();
  }, []);

  useEffect(() => {
    if (companyDataState.id !== "") {
      setFormData((oldFormData) => {
        const newFormData = { ...oldFormData };
        newFormData.companyNameSelect = companyDataState.companyName;
        newFormData.hrEmailIdSelect = companyDataState.hrEmailId;
        return newFormData;
      });
    }
  }, [companyDataState]);

  // select a company name from dropdown
  useEffect(() => {
    // fetch hr emails from selected company [FUNC]
    const fetchHrEmails = async (companyName: string) => {
      try {
        const { data } = await axios.get(
          `${rootEndpoint}/job-card/getHrEmails?companyName=${companyName}`
        );
        const HrData = data.data;

        setFormFields((ff) => {
          const newFormFields = { ...ff };
          newFormFields.hrEmailIdSelect.items = [];
          HrData.forEach((hr: { hrEmailId: string }) => {
            newFormFields.hrEmailIdSelect.items.push(hr.hrEmailId);
          });
          newFormFields.hrEmailIdSelect.items.push("Other");
          return newFormFields;
        });
      } catch (e) {
        console.error(e);
      }
    };

    const selectedCompanyName = formData.companyNameSelect;

    if (selectedCompanyName === "Other") {
      setFormFields((f) => {
        const newF = { ...f };
        newF.companyName.disabled = false; // enable the Company Name text field
        newF.hrEmailIdSelect.disabled = true; // disable HR Email dropdown
        newF.hrEmailId.disabled = false; // enable HR Email text field
        return newF;
      });
    } else {
      // fetch hr emails from selected company [CALL]
      fetchHrEmails(selectedCompanyName);

      setFormFields((f) => {
        const newF = { ...f };
        newF.companyName.disabled = true; // disable the Company Name text field
        newF.hrEmailIdSelect.disabled = false; // enable HR Email dropdown
        newF.hrEmailId.disabled = false; // enable HR Email text field
        return newF;
      });
    }

    // set HR Email Form Data to "Other"
    if (
      (formData.companyNameSelect != companyDataState.companyName &&
        formData.companyNameSelect != "Other") ||
      (formData.companyNameSelect == "Other" &&
        formData.hrEmailIdSelect != "Other")
    ) {
      setFormData((f) => {
        const newF = { ...f };
        newF.companyName = "";
        newF.hrEmailId = "";
        newF.hrEmailIdSelect = "Other";
        return newF;
      });
    }
  }, [formData.companyNameSelect]);

  // select an hr email from dropdown
  useEffect(() => {
    // fetch hr details from selected company and hr email [FUNC]
    const fetchAllHrDetails = async (hrEmailId: string) => {
      try {
        const { data } = await axios.get(
          `${rootEndpoint}/job-card/getCompanyFromHrEmailId?hrEmailId=${hrEmailId}`
        );
        const HrData = data.data;
        setFormData((f) => {
          const newF = { ...f };
          newF.hrName = HrData.hrName;
          newF.hrContactNumber = HrData.hrContactNumber;
          return newF;
        });
      } catch (e) {
        console.error(e);
      }
    };

    const selectedHrEmailId = formData.hrEmailIdSelect;

    if (selectedHrEmailId === "Other") {
      setFormData((f) => {
        const newF = { ...f };
        newF.hrName = "";
        newF.hrContactNumber = "";
        return newF;
      });
      setFormFields((f) => {
        const newF = { ...f };
        newF.hrEmailId.disabled = false; // enable HR Email text field
        return newF;
      });
    } else {
      // fetch hr details from selected company and hr email [CALL]
      fetchAllHrDetails(selectedHrEmailId);
      setFormFields((f) => {
        const newF = { ...f };
        newF.hrEmailId.disabled = true; // disable HR Email text field
        return newF;
      });
    }

    setFormData((f) => {
      const newF = { ...f };
      newF.hrEmailId = "";
      return newF;
    });
  }, [formData.hrEmailIdSelect]);

  // update form data in state
  const updateFormData = (fieldId: string, fieldValue: string) => {
    setFormData({
      ...formData,
      [fieldId]: fieldValue,
    });
  };
  // button trigger for back to dashboard
  const backToDashBoard = async () => {
    navigate("/");
  };

  // Handle Form Submit
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    let finalFormData: CompanyDataType = {
      companyName:
        formData.companyNameSelect === "Other"
          ? formData.companyName
          : formData.companyNameSelect,
      hrEmailId:
        formData.hrEmailIdSelect === "Other"
          ? formData.hrEmailId
          : formData.hrEmailIdSelect,
      hrName: formData.hrName,
      hrContactNumber: formData.hrContactNumber,
    };

    let dataForSubmission: CompanyDataType & { id?: string } = {
      ...finalFormData,
    };
    if (
      companyDataState.id &&
      formData.companyNameSelect != "Other" &&
      formData.hrEmailIdSelect != "Other"
    ) {
      dataForSubmission["id"] = companyDataState.id;
    }
    // post request at job-card/companies
    try {
      dispatch(postCompanyData(dataForSubmission));
    } catch (e) {
      console.error(e);
    }

    navigate("/jobDetailsForm");
  };

  const trackNameMap = useMemo(() => {
    return (
      allTracks?.reduce((acc: any, track: any) => {
        acc[track.id] = track.trackName;
        return acc;
      }, {}) ?? {}
    );
  }, [allTracks]);

  return (
    <div className="company-container">
      <button onClick={backToDashBoard}>Back</button>
      <h1 className="company-heading">Company Form</h1>

      {/* Form Begin */}
      <form onSubmit={handleSubmit}>
        <div className="form-item">
          <SubHeading title={"Tracks"} />
          <br />
          <FormControl fullWidth>
            <InputLabel>Tracks</InputLabel>
            <Select
              multiple
              required
              input={<OutlinedInput label="Tracks" />}
              renderValue={(selected: any) => {
                return selected
                  .map((track: any) => trackNameMap[track])
                  .join(", ");
              }}
              MenuProps={MenuProps}
              name={"tracksSelect"}
              value={selectedTracks ?? []}
              onChange={(e) => {
                const selectedValues = e.target.value;
                dispatch(setTracks(selectedValues));
              }}
            >
              {allTracks?.map((item: any, idx: number) => (
                <MenuItem key={idx} value={item.id}>
                  <ListItemText primary={item.trackName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
        </div>
        <div className="form-fields-container">
          {Object.entries(formFields).map(([id, field], idx): ReactElement => {
            if (field.type === "heading")
              return <SubHeading key={idx} title={field.label} />;
            if (field.type === "select")
              return (
                <SelectField
                  key={idx}
                  id={id}
                  {...field}
                  updateFormData={updateFormData}
                  formData={formData}
                />
              );
            return (
              <TextField
                key={idx}
                id={id}
                {...field}
                updateFormData={updateFormData}
                formData={formData}
              />
            );
          })}
        </div>
        <Button type="submit" variant="contained" className="company-btn">
          Submit
        </Button>
      </form>
      {/* Form End */}
    </div>
  );
};

const SubHeading = ({ title }: { title: string }) => {
  return (
    <>
      <h3 className="form-subheading">{title}</h3>
      <div></div>
    </>
  );
};

export default CompanyForm;
