const initialState=[];
export default (followUps = initialState , action) =>{
    switch(action.type){
        case 'GET_FOLLOW_UPS':
            return action.payload;

        case 'ADD_FOLLOW_UP':
            let followUpsData= [...followUps]; 
            followUpsData.unshift(action.payload);
            return followUpsData ;
        case 'REMOVE_FOLLOW_UPS':
            return [];
        default :
            return followUps;
    }
}