import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { placementCrmGridData } from "../../api";
import "./ChangeStage.css";
import ChangeStageTable from "./ChangeStageTable";

const JobCardGrid = () => {
  const location = useLocation();
  const [selectedRows, setSelectedRows] = useState([]);

  const jobCardID = location.search.split("=")[1];

  const [studentDetails, setStudentDetails] = useState<any>([]);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState<any>([]);
  const [rowsLength, setRowsLength] = useState<any>();

  useEffect(() => {
    placementCrmGridData(jobCardID).then((response) => {
      const res1 = response.data.data.data;
      setStudentDetails(res1);
    });
  }, []);
  useEffect(() => {
    try {
      const keys = Object.keys(studentDetails[0]);
      const filteredKeys = keys.filter(
        (key) =>
          key !== "email" &&
          key !== "currentStage" &&
          key !== "batch_manager_email"
      );

      let keyArray = [
        "email",
        "currentStage",
        "batch_manager_email",
        ...filteredKeys,
      ];

      setCols([
        { field: "id", headerName: "ID", width: 90 },
        ...keyArray?.map((item: any) => {
          return {
            field: item,
            headerName: item?.toUpperCase(),
            width: 250,
            editable: true,
          };
        }),
      ]);
    } catch (error) {
      console.error(error);
    }
  }, [studentDetails]);

  useEffect(() => {
    try {
      let temp: any = [];
      studentDetails?.forEach((item1: any, index: number) => {
        temp.push({ id: index, ...item1 });
      });
      setRows(temp);
      setRowsLength(temp.length);
    } catch (error) {
      console.error(error);
    }
  }, [cols]);

  return (
    <div className="change-state-section" style={{ marginTop: "100px" }}>
      <ChangeStageTable
        rows={rows}
        rowsLength={rowsLength}
        columns={cols}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default JobCardGrid;
