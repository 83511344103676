import * as api from "../api";

export const getViews = () => async (dispatch) => {
  try {
    const { data } = await api.fetchViews();
    dispatch({ type: "FETCH_ALL", payload: data });
  } catch (error) {
    console.error(error);
  }
};
