const initialState=[];
export default (dailyFollowUps = initialState , action) =>{
    switch(action.type){
        case 'GET_DAILY_FOLLOW_UPS':
            return action.payload;

        case 'DELETE_ONE_DAILY_FOLLOW_UP':
            let dailyFollowUpsData= [...dailyFollowUps]; 
            dailyFollowUpsData.filter((followUp)=>{
                return (followUp.studentId !== action.payload)} );
            return dailyFollowUpsData ;
        default :
            return dailyFollowUps;
    }
}