import { Button } from "@mui/material";
import axios from "axios";
import { startCase } from "lodash";
import {
  FormEventHandler,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../App";
import {
  emptyJobCardStateAndCompanyState,
  getSessionIds,
} from "../../actions/jobCard";
import MockScoreBlock from "./MockScoreBlock";
import { city } from "./cities";
import MultiSelectField from "./formElements/MultiSelectField";
import SelectField from "./formElements/SelectField";
import TextField from "./formElements/TextField";
import "./styles.css";
import { rootEndpoint } from "../../config/endpoint";

export interface IndividualMockScoreType {
  sessionName: string;
  condition: string;
  score: number;
}

export interface MockScoreBlockType {
  statement: IndividualMockScoreType[];
  description: string;
}

export interface FormDataType {
  class10Percentage: number;
  class12Percentage: number;
  graduationPercentage: number;
  collegeDegree: string[];
  collegeDepartment: string[];
  graduationYearStart: number;
  graduationYearEnd: number;
  workExperienceMonths: number;
  workExperienceMonthsMax: number;
  activeBacklogs: number;
  jobStatus: string[];
  gender: string[];
  jobType: string[];
  mockScore: MockScoreBlockType[];
  jobCard: string;
  jobLocationState: string[];
  jobLocationCity: string[];
}

export interface FormFieldProps {
  id: string;
  label: string;
  type: string;
  items?: Array<string>;
  updateFormData: Function;
  formData: FormDataType;
  required: boolean;
}

const emptyArray: string[] = [];

const FormFields = {
  class10Percentage: {
    label: "Minimum 10th Percentage [Number]",
    type: "number",
  },
  class12Percentage: {
    label: "Minimum 12th Percentage [Number]",
    type: "number",
  },
  graduationPercentage: {
    label: "Minimum Graduation Percentage [Number]",
    type: "number",
  },
  collegeDegree: {
    label: "Degree",
    type: "multiselect",
    items: emptyArray,
  },
  collegeDepartment: {
    label: "Branch",
    type: "multiselect",
    items: [],
  },
  activeBacklogs: {
    label: "Maximum Active backlogs [Number]",
    type: "number",
  },
  graduationYearStart: {
    label: "Minimum year of graduation [Number]",
    type: "number",
  },
  graduationYearEnd: {
    label: "Maximum year of graduation [Number]",
    type: "number",
  },
  workExperienceMonths: {
    label: "Minimum work experience in months",
    type: "number",
  },
  workExperienceMonthsMax: {
    label: "Maximum work experience in months",
    type: "number",
  },
  jobStatus: {
    label: "Job status",
    type: "multiselect",
    items: [],
  },
  gender: {
    label: "Gender",
    type: "multiselect",
    items: ["Male", "Female", "Others"],
  },
  jobType: {
    label: "Job Type",
    type: "multiselect",
    items: [],
  },
  jobLocationState: {
    label: "State",
    type: "multiselect",
    items: [...Object.keys(city)],
  },
  jobLocationCity: {
    label: "City",
    type: "multiselect",
    items: [],
  },
};

const EligibilityForm: React.FC = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const jobCardDataState = useSelector(
    (state) => ((state as any).jobCard as any).jobCardData
  );
  const jobCardEligibilityState = useSelector(
    (state) => ((state as any).jobCard as any).eligibilityData
  );

  const companyDataState = useSelector(
    (state) => ((state as any).jobCard as any).companyData
  );

  const sessionDetailsState: { sessionId: string; sessionType: string }[] =
    useSelector((state) => ((state as any).jobCard as any).sessionDetails) ??
    [];

  const [showMockScoreBlock, setShowMockScoreBlock] = useState(false);

  const initialMockScore: MockScoreBlockType[] = [
    {
      statement: [
        {
          sessionName: "",
          condition: "",
          score: 0,
        },
      ],
      description: "",
    },
  ];

  const initialFormData: FormDataType = {
    class10Percentage: NaN,
    class12Percentage: NaN,
    graduationPercentage: NaN,
    collegeDegree: [] as string[],
    collegeDepartment: [] as string[],
    graduationYearStart: NaN,
    graduationYearEnd: NaN,
    workExperienceMonths: NaN,
    workExperienceMonthsMax: NaN,
    activeBacklogs: NaN,
    jobStatus: [] as string[],
    gender: [] as string[],
    jobType: [] as string[],
    mockScore: [],
    jobCard: jobCardDataState.id,
    jobLocationState: [] as string[],
    jobLocationCity: [] as string[],
  };
  const temp: any = [];
  const [formFields, setFormFields] = useState(FormFields);
  const [collegeDegreeOptions, setCollegeDegreeOptions] = useState(temp);
  const [collegeDepartmentOptions, setCollegeDepartmentOptions] =
    useState(temp);

  const getSessionIdsOfJobCard = async () => {
    const { data } = await axios.get(
      `${rootEndpoint}/job-card/placementSessions`
    );
    dispatch(getSessionIds(data));
    const prefillCompanyDetails = (data: any) => {
      const eligibilityData = jobCardEligibilityState;
      setShowMockScoreBlock(true);
      setFormData((oldFormData) => {
        const newFormData = { ...oldFormData };
        newFormData.activeBacklogs =
          eligibilityData.activeBacklogs != undefined
            ? eligibilityData.activeBacklogs
            : newFormData.activeBacklogs;
        newFormData.class10Percentage =
          eligibilityData.class10Percentage != undefined
            ? eligibilityData.class10Percentage
            : newFormData.class10Percentage;
        newFormData.class12Percentage =
          eligibilityData.class12Percentage != undefined
            ? eligibilityData.class12Percentage
            : newFormData.class12Percentage;
        newFormData.collegeDegree =
          eligibilityData.collegeDegree != undefined
            ? eligibilityData.collegeDegree
            : newFormData.collegeDegree;
        newFormData.collegeDepartment =
          eligibilityData.collegeDepartment != undefined
            ? eligibilityData.collegeDepartment
            : newFormData.collegeDepartment;
        newFormData.graduationPercentage =
          eligibilityData.graduationPercentage != undefined
            ? eligibilityData.graduationPercentage
            : newFormData.graduationPercentage;
        newFormData.graduationYearStart =
          eligibilityData.graduationYearStart != undefined
            ? eligibilityData.graduationYearStart
            : newFormData.graduationYearStart;
        newFormData.graduationYearEnd =
          eligibilityData.graduationYearEnd != undefined
            ? eligibilityData.graduationYearEnd
            : newFormData.graduationYearEnd;
        newFormData.workExperienceMonths =
          eligibilityData.workExperienceMonths != undefined
            ? eligibilityData.workExperienceMonths
            : newFormData.workExperienceMonths;
        newFormData.workExperienceMonthsMax =
          eligibilityData.workExperienceMonthsMax != undefined
            ? eligibilityData.workExperienceMonthsMax
            : newFormData.workExperienceMonthsMax;
        newFormData.jobStatus =
          eligibilityData.jobStatus != undefined
            ? eligibilityData.jobStatus
            : newFormData.jobStatus;
        newFormData.gender =
          eligibilityData.gender != undefined
            ? eligibilityData.gender
            : newFormData.gender;
        //@ts-ignore
        eligibilityData.mockScore.forEach((mockScoreAndCondition) => {
          //@ts-ignore
          mockScoreAndCondition.statement.forEach((element) => {
            if (!element.sessionName) {
              const found = data.find(
                //@ts-ignore
                (session) => element.sessionId === session.id
              );
              //@ts-ignore
              element.sessionName = found?.sessionType;
              //@ts-ignore
              delete element.sessionId;
            }
          });
        });
        newFormData.mockScore =
          eligibilityData.mockScore != undefined
            ? eligibilityData.mockScore
            : newFormData.mockScore;
        newFormData.jobType =
          eligibilityData.jobType != undefined
            ? eligibilityData.jobType
            : newFormData.jobType;
        newFormData.jobLocationState =
          eligibilityData.jobLocationState != undefined
            ? eligibilityData.jobLocationState.map((x: string) =>
                x.replace(/ /g, "_")
              )
            : newFormData.jobLocationState.map((x) => x.replace(/ /g, "_"));
        newFormData.jobLocationCity =
          eligibilityData.jobLocationCity != undefined
            ? eligibilityData.jobLocationCity
            : newFormData.jobLocationCity;
        return newFormData;
      });
      setPrefilledId(eligibilityData.id);
    };
    if (Object.keys(jobCardEligibilityState).length !== 0) {
      prefillCompanyDetails(data);
    }
  };

  useEffect(() => {
    getSessionIdsOfJobCard();
  }, []);

  // fetch multiselect values
  useEffect(() => {
    const getMultiSelectValues = async () => {
      try {
        const { data } = await axios.get(
          `${rootEndpoint}/job-card/multiSelectValuesEligibility`
        );
        const fields = data.data;

        setFormFields((formFields) => {
          const newFormFields: any = { ...formFields };
          for (const [key, value] of Object.entries(fields)) {
            (newFormFields as any)[key].items = value;
            if (
              key === "collegeDegree" &&
              newFormFields.collegeDegree.items.indexOf("All") < 0
            ) {
              setCollegeDegreeOptions(value);
              newFormFields.collegeDegree.items.push("All");
            }
            if (
              key === "collegeDepartment" &&
              newFormFields.collegeDepartment.items.indexOf("All") < 0
            ) {
              setCollegeDepartmentOptions(value);
              newFormFields.collegeDepartment.items.push("All");
            }
          }
          return newFormFields;
        });
      } catch (e) {
        console.error(e);
      }
    };
    getMultiSelectValues();
  }, []);

  const sessionTypes: string[] =
    (sessionDetailsState?.map((session) => {
      return session.sessionType;
    }) as unknown as any) || [];
  const [formData, setFormData] = useState(initialFormData);
  const [prefilledId, setPrefilledId] = useState("");
  const [districtItems, setDistrictItems] = useState<string[]>([]);

  const updateFormData = (fieldId: string, fieldValue: string | string[]) => {
    if (fieldId === "jobLocationState") {
      let tempDistrict: string[] = [];
      if (typeof fieldValue === "object" && fieldValue.length > 0) {
        fieldValue.forEach(
          (elem: string) =>
            (tempDistrict = [
              `$${elem}`,
              ...tempDistrict,
              //   @ts-ignore
              ...city[elem.replace(/ /g, "_")],
            ])
        );
        setDistrictItems(tempDistrict);
      }

      let currCityList = formData.jobLocationCity;
      formData.jobLocationCity.forEach((elm) => {
        if (!tempDistrict.includes(elm)) {
          currCityList = currCityList.filter((el, indx) => {
            return el !== elm;
          });
        }
      });

      setFormData({
        ...formData,
        jobLocationCity: currCityList,
        // @ts-ignore
        [fieldId]: fieldValue,
      });
    } else if (fieldId === "jobLocationCity") {
      if (typeof fieldValue === "object" && fieldValue.length > 0) {
        let tempDistrict: string[] = [];

        fieldValue.forEach((dist) => {
          if (dist[0] === "$") {
            let st = dist.slice(1);
            //   @ts-ignore
            let allDist = city[st];
            tempDistrict = [...tempDistrict, ...allDist];
          } else tempDistrict = [...tempDistrict, dist];
        });
        setFormData({
          ...formData,
          [fieldId]: Array.from(new Set(tempDistrict)),
        });
      } else
        setFormData({
          ...formData,
          // @ts-ignore
          [fieldId]: fieldValue,
        });
    } else
      setFormData({
        ...formData,
        [fieldId]: fieldValue,
      });
  };

  const addIndividualMockScore = () => {
    const newMockScoreData = [...formData.mockScore];
    newMockScoreData
      .at(-1)
      ?.statement.push({ ...initialMockScore[0].statement[0] });
    setFormData({ ...formData, mockScore: newMockScoreData });
  };

  const addMockScoreBlock = () => {
    const newMockScoreData = [...formData.mockScore];
    newMockScoreData.push({ ...initialMockScore[0] });
    setFormData({ ...formData, mockScore: newMockScoreData });
  };

  const updateMockScoreBlockDescription = (blockIdx: number, value: string) => {
    const newMockScoreData = [...formData.mockScore];
    newMockScoreData[blockIdx].description = value;
    setFormData({ ...formData, mockScore: newMockScoreData });
  };

  const updateIndividualMockScore = (
    blockIdx: number,
    individualIdx: number,
    element: string,
    value: string,
    type: string
  ) => {
    let newMockScoreData = [...formData.mockScore];

    if (type === "update") {
      // update individual mock score
      if (element === "sessionName" || element === "condition")
        newMockScoreData[blockIdx].statement[individualIdx][element] = value;
      else if (element === "score")
        newMockScoreData[blockIdx].statement[individualIdx].score = +value;
    } else {
      // delete individual mock score
      newMockScoreData[blockIdx].statement.splice(individualIdx, 1);

      if (newMockScoreData[blockIdx].statement.length === 0)
        newMockScoreData.splice(blockIdx, 1);

      if (newMockScoreData.length === 0)
        newMockScoreData = [...initialMockScore];
    }

    setFormData({ ...formData, mockScore: newMockScoreData });
  };

  const navigateJobDetails = () => {
    try {
      navigate("/jobDetailsForm");
    } catch (e) {
      console.error(e);
    }
  };

  // Handle Form Submit
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setLoading(true);
    const finalFormData = { ...formData };
    let submissionData: FormDataType & { id?: string } = { ...finalFormData };
    submissionData.jobCard = jobCardDataState.id;
    if (
      submissionData.collegeDegree.length != 0 &&
      submissionData.collegeDegree[0] === "All"
    ) {
      submissionData.collegeDegree = formFields.collegeDegree.items;
      let tempId = submissionData.collegeDegree.indexOf("All");
      if (tempId > -1) {
        submissionData.collegeDegree.splice(tempId, 1);
      }
    }
    if (
      submissionData.collegeDepartment.length != 0 &&
      submissionData.collegeDepartment[0] === "All"
    ) {
      submissionData.collegeDepartment = formFields.collegeDepartment.items;
      let tempId = submissionData.collegeDepartment.indexOf("All");
      if (tempId > -1) {
        submissionData.collegeDepartment.splice(tempId, 1);
      }
    }
    finalFormData.mockScore.forEach((mockScoreAndCondition) => {
      mockScoreAndCondition.statement.forEach((element) => {
        const found = sessionDetailsState.find(
          (session) => element.sessionName === session.sessionType
        );
        //@ts-ignore
        element.sessionId = found?.id;
        //@ts-ignore
        delete element.sessionName;
      });
    });

    // submissionData.jobLocationState = submissionData.jobLocationState.map(state => state.replace(/_/g, ' '));

    if (prefilledId !== "") submissionData.id = prefilledId;
    // post request to job-card/eligibility
    await axios.post(`${rootEndpoint}/job-card/eligibility`, submissionData);
    setLoading(false);

    // back to dashboard
    navigate("/");

    // empty jobCard and CompanyCard states
    dispatch(emptyJobCardStateAndCompanyState());
  };

  // get eligibility
  const eligibilityForm = useRef<HTMLFormElement>(null);

  const getEligibleStudents = async () => {
    if (eligibilityForm && eligibilityForm.current) {
      if (eligibilityForm.current.checkValidity()) {
        // data
        const finalFormData = JSON.parse(JSON.stringify(formData));
        const idx = finalFormData.collegeDegree.indexOf("All");
        if (idx > -1) {
          finalFormData.collegeDegree = formFields.collegeDegree.items.slice(
            0,
            -1
          );
        }
        const tempIdx = finalFormData.collegeDepartment.indexOf("All");
        if (tempIdx > -1) {
          finalFormData.collegeDepartment =
            formFields.collegeDepartment.items.slice(0, -1);
        }
        //@ts-ignore
        finalFormData.mockScore.forEach((mockScoreAndCondition) => {
          //@ts-ignore
          mockScoreAndCondition.statement.forEach((element) => {
            const found = sessionDetailsState.find(
              (session) => element.sessionName === session.sessionType
            );
            //@ts-ignore
            element.sessionId = found?.id;
            //@ts-ignore
            delete element.sessionName;
          });
        });

        setLoading(true);
        // post request to job-card/eligibility
        const companyName: string = companyDataState.companyName;
        const req = await axios.post(
          `${rootEndpoint}
						/job-card/eligibleStudentDataFromEligibility
						?companyName=${companyName}
						&maxCtc=${
              jobCardDataState.maxCtcOffered
                ? jobCardDataState.maxCtcOffered
                : 1000
            }`,
          finalFormData
        );
        const items = req.data.data;
        if (items.length === 0) {
          alert("No eligible students found.");
          setLoading(false);
          return;
        }
        const replacer = (_key: string, value: string) =>
          value === null ? "" : value; // specify how you want to handle null values here
        const header = Object.keys(items[0]);
        const csv = [
          header.map(startCase).join(","), // header row first
          ...items.map((row: any) =>
            header
              .map((fieldName) => JSON.stringify(row[fieldName], replacer))
              .join(",")
          ),
        ].join("\r\n");

        const exportedFilename = "eligible-students.csv";

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        setLoading(false);
      } else {
        console.error("invalid");
      }
    }
  };

  const getEligibleStudentCount = async () => {
    if (eligibilityForm && eligibilityForm.current) {
      if (eligibilityForm.current.checkValidity()) {
        // data
        const finalFormData = JSON.parse(JSON.stringify(formData));

        const idx = finalFormData.collegeDegree.indexOf("All");
        if (idx > -1) {
          finalFormData.collegeDegree = formFields.collegeDegree.items.slice(
            0,
            -1
          );
        }
        const tempIdx = finalFormData.collegeDepartment.indexOf("All");
        if (tempIdx > -1) {
          finalFormData.collegeDepartment =
            formFields.collegeDepartment.items.slice(0, -1);
        }
        //@ts-ignore
        finalFormData.mockScore.forEach((mockScoreAndCondition) => {
          //@ts-ignore
          mockScoreAndCondition.statement.forEach((element) => {
            const found = sessionDetailsState.find(
              (session) => element.sessionName === session.sessionType
            );
            //@ts-ignore
            element.sessionId = found?.id;
            //@ts-ignore
            delete element.sessionName;
          });
        });
        setLoading(true);

        // post request to job-card/eligibility
        const req = await axios.post(
          `${rootEndpoint}/job-card/eligibleStudentCountFromEligibility?maxCtc=${
            jobCardDataState.maxCtcOffered
              ? jobCardDataState.maxCtcOffered
              : 1000
          }`,
          finalFormData
        );
        const res = req.data.data;
        alert(`Number of eligible students: ${res}`);
        setLoading(false);
      }
    }
  };

  return (
    <div className="placement-container">
      <button onClick={navigateJobDetails}>Back</button>
      <h1 className="heading">Eligibility Form</h1>

      {/* Form Begin */}
      <form onSubmit={handleSubmit} ref={eligibilityForm}>
        {/* Basic Fields Begin */}
        <div className="form-fields-container">
          {Object.entries(formFields).map(([id, field], idx): ReactElement => {
            if (field.type === "select")
              return (
                <SelectField
                  key={idx}
                  id={id}
                  {...field}
                  updateFormData={updateFormData}
                  formData={formData}
                  required={false}
                />
              );
            else if (field.type === "multiselect") {
              if (field.label === "City") {
                if (formData["jobLocationState"].length === 0) return <></>;
                else if (districtItems.length > 0)
                  return (
                    <MultiSelectField
                      key={idx}
                      id={id}
                      {...field}
                      items={districtItems}
                      updateFormData={updateFormData}
                      formData={formData}
                      required={false}
                    />
                  );
                else return <></>;
              } else
                return (
                  <MultiSelectField
                    key={idx}
                    id={id}
                    {...field}
                    updateFormData={updateFormData}
                    formData={formData}
                    required={false}
                  />
                );
            }
            return (
              <TextField
                key={idx}
                id={id}
                {...field}
                updateFormData={updateFormData}
                formData={formData}
                required={false}
              />
            );
          })}
        </div>
        {/* Basic Fields End */}

        {/* Mock Score Fields Begin */}
        <div className="mock-score-container">
          <h3>Mock Score Eligibility</h3>

          <div>
            <Button
              className="logical-operator and"
              style={{ width: "140px" }}
              onClick={() => {
                setShowMockScoreBlock(!showMockScoreBlock);
                setFormData((oldFormData) => {
                  const newFormData = { ...oldFormData };
                  newFormData.mockScore = [
                    {
                      statement: [
                        {
                          sessionName: "",
                          condition: "",
                          score: 0,
                        },
                      ],
                      description: "",
                    },
                  ];
                  return newFormData;
                });
              }}
            >
              Select
            </Button>
          </div>
          {showMockScoreBlock &&
            formData.mockScore.map((mockScoreBlockData, idx) => (
              <MockScoreBlock
                key={idx}
                blockIdx={idx}
                data={mockScoreBlockData}
                addMockScoreBlock={addMockScoreBlock}
                updateMockScoreBlockDescription={
                  updateMockScoreBlockDescription
                }
                addIndividualMockScore={addIndividualMockScore}
                updateIndividualMockScore={updateIndividualMockScore}
                sessionTypes={sessionTypes}
              />
            ))}
        </div>
        {/* Mock Score Fields End */}
        <br></br>
        <div className="eligibility-form-buttons">
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            className="btn"
          >
            Submit
          </Button>
          <Button
            onClick={getEligibleStudents}
            disabled={loading}
            variant="contained"
            className="btn"
          >
            Get Eligible Students
          </Button>
          <Button
            onClick={getEligibleStudentCount}
            disabled={loading}
            variant="contained"
            className="btn"
          >
            Get Eligible Student Count
          </Button>
          <p style={{ display: loading ? "" : "none" }}>
            Please Wait, Loading...
          </p>
        </div>
      </form>
      {/* Form End */}
    </div>
  );
};

export default EligibilityForm;
