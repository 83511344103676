import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FormDataType, FormFieldProps } from "../JobDetailsForm";

const SelectField = ({
  id,
  label,
  items,
  updateFormData,
  formData,
  required
}: FormFieldProps) => {
  return (
    <div className='form-item'>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          required={required}
          value={formData[id as keyof FormDataType]}
          name={id}
          label={label}
          onChange={(e) => {
            updateFormData(e.target.name, e.target.value);
          }}
        >
          {items?.map((item, idx) => (
            <MenuItem key={idx} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectField;
