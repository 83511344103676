const initialState=[];
export default (kpiData= initialState , action) =>{
    switch(action.type){
        case 'GET_BATCH_MANAGERS_KPI_DATA':
            return action.payload;


        case 'REMOVE_BATCH_MANAGERS_KPI_DATA':
            return [];
        default :
            return kpiData;
    }
}