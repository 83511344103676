import { ENDPOINTS as prodEndpoints } from "./prod";
import { ENDPOINTS as stageEndPoints } from "./stage";

const endpointProvider = () => {
  if (process.env.NODE_ENV === "production") {
    return prodEndpoints;
  } else return stageEndPoints;
};

export const rootEndpoint = endpointProvider().ROOT;
