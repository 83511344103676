const initialState = [];

export default (adminData = initialState, action) => {
  switch (action.type) {
    case "GET_ADMIN_DATA":
      return action.payload;
    case "UPDATE_DATA_OF_ADMIN_STUDENT":
      let newFullDataState = adminData.map((student) =>
        student.studentId === action.payload.studentId
          ? action.payload
          : student
      );
      return newFullDataState;
    default:
      return adminData;
  }
};
