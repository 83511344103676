import WorkIcon from "@mui/icons-material/Work";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { fetchJobCardsApi } from "../../api";
import { rootEndpoint } from "../../config/endpoint";
import { JobCardDetailModal } from "./JobCardDetailModal";
import "./PlacementDashboard.module.css";
import styles from "./PlacementDashboard.module.css";
import { Button } from "./components/Button";
import { SearchBox } from "./components/SearchBox";
import { JobCardDetailsType, JobCardType } from "./types/JobCardDetails";
import { formatDate } from "./utils";

const PlacementDashboard = () => {
  const navigate = useNavigate();
  const [jobCards, setJobCards] = useState<Array<JobCardType>>([]);
  const [loading, setLoading] = useState(true);
  const cursorId = useRef<string | null>(null);

  const [activeJobCardDetails, setActiveJobCardDetails] =
    useState<JobCardDetailsType | null>(null);
  const [detailsFetchingLoading, setDetailsFetchingLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchJobCards = async (searchText = "") => {
    setLoading(true);
    try {
      const { data } = await fetchJobCardsApi(cursorId.current, searchText);

      const newJobCards: JobCardType[] = data.data.jobCards;
      cursorId.current = data.data.cursorId;
      setHasMore(data.data.hasMore);

      setJobCards((jobCards) => [...jobCards, ...newJobCards]);
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobCards();
  }, []);

  const goToCompanyForm = () => {
    navigate("/companyForm");
  };

  const getDetailsOfJobCard = async (jobCardId: string) => {
    setDetailsFetchingLoading(true);
    try {
      const { data } = await axios.get(
        `${rootEndpoint}/job-card/getJobCardDetailsForInternalTool?jobCardId=${jobCardId}`
      );
      setActiveJobCardDetails(data.data);
    } catch (error) {
      console.error("Error fetching job card details:", error);
    } finally {
      setDetailsFetchingLoading(false);
    }
  };

  const onCloned = (clonedJobCard: JobCardType) => {
    setJobCards([clonedJobCard, ...jobCards]);
    setActiveJobCardDetails(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        backgroundColor: "white",
        cursor:
          detailsFetchingLoading || detailsFetchingLoading ? "wait" : "auto",
      }}
    >
      <div
        className={styles.header}
        style={{
          position: "sticky",
          top: 0,
          paddingTop: 75,
          paddingBottom: 24,
          zIndex: 1000,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <h1>Placement Dashboard</h1>
        <SearchBox
          onSearch={(searchText) => {
            cursorId.current = null;
            setJobCards([]);
            fetchJobCards(searchText);
          }}
          onClear={() => {
            cursorId.current = null;
            setJobCards([]);
            fetchJobCards();
          }}
        />

        <Button
          onClick={goToCompanyForm}
          style={{
            opacity: 1,
            pointerEvents: "auto",
          }}
          title="Add Job Card"
        />
      </div>

      <div className={styles.addedJobs}>
        <h2>Registered Jobs</h2>
        <div className={styles.addedJobsGrid}>
          {jobCards.length === 0 && loading === false && (
            <p>No Job Cards Available!</p>
          )}
          {jobCards.map((card, idx) => (
            <div
              className={styles.jobCard}
              style={{
                cursor: loading || detailsFetchingLoading ? "wait" : "pointer",
              }}
              key={idx}
              onClick={() => getDetailsOfJobCard(card.id)}
            >
              <h3>
                <span>
                  <WorkIcon />
                </span>
                {card.companyName} - {card.driveName}
              </h3>
              <h4>
                HR Name - <span>{card.hrName}</span>
              </h4>
              <h4>
                HR Contact Number - <span>{card.hrContactNumber}</span>
              </h4>
              <h4>
                Deadline - <span>{formatDate(card.deadline)}</span>
              </h4>
              <h4>
                Application Link -{" "}
                <a
                  href={"https://refs.acciojob.com/jobs?job=" + card.id}
                  target="_blank"
                  rel="noreferrer"
                >
                  Link
                </a>
              </h4>
              <h4>
                IsPublished - <span>{card.isPublished ? "True" : "False"}</span>
              </h4>
              <h4>
                IsHidden - <span>{card.isHidden ? "True" : "False"}</span>
              </h4>
              {card.endAt ? (
                <h4>
                  End Date - <span>{formatDate(card.endAt)}</span>
                </h4>
              ) : null}

              {card?.opsPOC ? (
                <h4>
                  OPS POC - <span>{card.opsPOC}</span>
                </h4>
              ) : null}

              {card?.reachoutTeamPOC ? (
                <h4>
                  Reachout Team POC - <span>{card.reachoutTeamPOC}</span>
                </h4>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      {hasMore ? (
        <Button
          loading={loading}
          onClick={() => fetchJobCards()}
          style={{ margin: 24 }}
          title="Load More"
        />
      ) : null}
      <JobCardDetailModal
        jobCard={activeJobCardDetails}
        onClose={() => setActiveJobCardDetails(null)}
        onCloned={onCloned}
      />
    </div>
  );
};

export default PlacementDashboard;
