const initialState = [{ id: "5", BMId: "asa", views: [{}] }];

export default (viewsState = initialState, action: any) => {
  switch (action.type) {
    case "FETCH_ALL":
      return action.payload;
    case "CREATE":
      let views = [...viewsState];
      views.push(action.payload);
      return views;
    // case 'EDIT':
    //     return {...state , views: state.views.map((view) => view.id === action.payload.id ? action.payload : view)};
    case "DELETE_VIEW":
      let Newviews = [...viewsState];
      let result = Newviews.filter((view) => {
        return view.id !== action.payload;
      });
      return result;
    default:
      return viewsState;
  }
};
