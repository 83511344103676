const initialState = {
  companyData: {},
  jobCardData: {},
  eligibilityData: {},
};

const jobCard = (jobCardState = initialState, action) => {
  let newState;
  switch (action.type) {
    case "POSTCOMPANYDATA":
      newState = { ...jobCardState };
      newState.companyData = action.payload.data;
      return newState;

    case "POSTJOBCARDDATA":
      newState = { ...jobCardState };
      newState.jobCardData = action.payload;
      return newState;

    case "SETEDITJOBCARDDETAILS":
      newState = { ...jobCardState };
      newState.companyData = action.payload.companyDetails
        ? action.payload.companyDetails
        : {};
      newState.jobCardData = action.payload.jobCard
        ? action.payload.jobCard
        : {};
      newState.eligibilityData = action.payload.jobCardEligibility
        ? action.payload.jobCardEligibility
        : {};
      newState.tracks = action.payload.tracks
        ? action.payload.tracks.map((item) => item.id)
        : [];
      return newState;

    case "SET_SELECTED_TRACKS": {
      newState = { ...jobCardState };
      newState.tracks = action.payload;
      return newState;
    }

    case "SET_ALL_TRACKS": {
      newState = { ...jobCardState };
      newState.allTracks = action.payload;
      return newState;
    }

    case "SETSESSIONIDS":
      newState = { ...jobCardState };
      newState.sessionDetails = action.payload;
      return newState;

    case "POSTJOBCARDELIGIBILITYDATA":
      newState = { ...jobCardState };
      newState.eligibilityData = action.payload;
      return newState;

    case "EMPTYSTATE":
      return {
        companyData: {},
        jobCardData: {},
        eligibilityData: {},
      };

    default:
      return jobCardState;
  }
};

export default jobCard;
