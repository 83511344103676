import { TextField as MaterialTextField } from "@mui/material";
import { FormDataType, FormFieldProps } from "../EligibilityForm";

const TextField = ({
  id,
  label,
  updateFormData,
  formData,
  type,
  required
}: FormFieldProps) => {
  return (
    <div className='form-item'>
      <MaterialTextField
        required={required}
        type={type}
        id='outlined-basic'
        label={label}
        variant='outlined'
        style={{ width: "100%" }}
        name={id}
        value={formData[id as keyof FormDataType]}
        onChange={(e) => updateFormData(e.target.name, e.target.value)}
      />
    </div>
  );
};

export default TextField;
