import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from "@mui/material";
import { FormDataType, FormFieldProps } from "../EligibilityForm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelectField = ({
  id,
  label,
  items,
  updateFormData,
  formData,
  required=true
}: FormFieldProps) => {
  return (
    <div className='form-item'>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          required={required}
          input={<OutlinedInput label='Tag' />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          name={id}
          value={formData[id as keyof FormDataType] as string[]}
          onChange={(e) => {
            updateFormData(
              e.target.name,
              typeof e.target.value === "string"
                ? e.target.value.split(",")
                : e.target.value
            );
          }}
        >
          {items?.map((item, idx) => (
            <MenuItem key={idx} value={item}>
              <Checkbox
                checked={
                  (formData[id as keyof FormDataType] as string[]).indexOf(
                    item
                  ) > -1
                }
              />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultiSelectField;
