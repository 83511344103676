import { Container } from "@mui/material";
import { useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { PLACEMENT_CRM_TOKEN_NAME } from "../../config";

export default function Authenticate() {
  const [searchParams, _] = useSearchParams();
  useLayoutEffect(() => {
    const token = searchParams.get("token");
    if (!token) {
      alert("No token found in URL");
      return;
    }
    localStorage.setItem(PLACEMENT_CRM_TOKEN_NAME, token);
    window.location.href = "/";
  }, []);

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
      <div>Authenticate</div>
    </Container>
  );
}
