import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { IndividualMockScoreType } from "./EligibilityForm";
import CloseIcon from "@mui/icons-material/Close";

const relationalOperators = ["<", ">"];

const IndividualMockScore = ({
  idx,
  blockIdx,
  data,
  addIndividualMockScore,
  updateIndividualMockScore,
  sessionTypes,
}: {
  idx: number;
  blockIdx: number;
  data: IndividualMockScoreType;
  addIndividualMockScore: () => void;
  updateIndividualMockScore: (
    blockIdx: number,
    individualIdx: number,
    element: string,
    value: string,
    type: string
  ) => void;
  sessionTypes: string[];
}) => {
  return (
    <div className="individual-mock-score">
      <div className="form-item">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Session Name</InputLabel>
          <Select
            required
            name={blockIdx + idx + "-sn"}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={data.sessionName}
            label="Session Name"
            onChange={(e) => {
              updateIndividualMockScore(
                blockIdx,
                idx,
                "sessionName",
                e.target.value,
                "update"
              );
            }}
          >
            {sessionTypes.map((item, idx) => (
              <MenuItem key={idx} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="form-item">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Relational Operator
          </InputLabel>
          <Select
            required
            name={blockIdx + idx + "-c"}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={data.condition}
            label="Relational Operator"
            onChange={(e) => {
              updateIndividualMockScore(
                blockIdx,
                idx,
                "condition",
                e.target.value,
                "update"
              );
            }}
          >
            {relationalOperators.map((item, idx) => (
              <MenuItem key={idx} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="form-item">
        <TextField
          required
          type="number"
          name={blockIdx + idx + "-sc"}
          id="outlined-basic"
          label="Score"
          variant="outlined"
          style={{ width: "100%" }}
          value={data.score}
          onChange={(e) => {
            updateIndividualMockScore(
              blockIdx,
              idx,
              "score",
              e.target.value,
              "update"
            );
          }}
        />
      </div>

      <Button className="logical-operator or" onClick={addIndividualMockScore}>
        OR
      </Button>

      <Button
        className="delete-button"
        onClick={() => {
          updateIndividualMockScore(blockIdx, idx, "score", "", "delete");
        }}
      >
        <CloseIcon />
      </Button>
    </div>
  );
};

export default IndividualMockScore;
