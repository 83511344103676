const initialState = [];

export default (viewDataState = initialState, action) => {
  switch (action.type) {
    case "FETCH_ONE":
      let views = action.payload;
      let view = views.filter((v) => v.id == action.viewId);
      return view;
    case "REMOVE_ONE":
      return [];

    default:
      return viewDataState;
  }
};
