import React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import { TextField } from "@mui/material";

interface Props {
  showForm: boolean;
  setShowForm: any;
  stage: any;
  stageName: string;
  setStageName: any;
  ValidateEmail: any;
  selectedEmails: any;
  emailToggle: boolean;
}

const reasonsOfRejection = [
  "Not applied",
  "Not comfortable for Relocation",
  "Not comfortable with the Bond offered",
  "Not comfortable with the CTC",
  "Resume not up to the mark",
  "Denied for internship",
  "Denied for F2F interview",
  "Not proficient in the required skill",
  "Did not attend the HR's call",
  "Did not submit the assignment",
  "Did not attempt the test",
  "Failed at test",
  "Failed at assignment",
  "Did not give the interview",
  "Was late for interview",
  "Interview did not go well",
  "Did not attend the HR round",
  "Rejected the offer letter",
  "Refused the joining date",
  "Refused the salary offered",
  "Plagiarism",
  "tier 1 college preferred",
  "Not situated in preferred location of company",
  "Other",
];

export default function StageChangeModal({
  stage,
  setStageName,
  ValidateEmail: validateEmail,
  selectedEmails,
  showForm,
  stageName,
  setShowForm,
  emailToggle,
}: Props) {
  const [selectedReason, setSelectedReason] = React.useState("");

  const handleReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    setSelectedReason(selectedValue);
  };

  return (
    <React.Fragment>
      <Modal open={showForm} onClose={() => setShowForm(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "flex-end",
              pt: 2,
              flexDirection: "column",
              width: "35vw",
            }}
          >
            <div className="changeStage" id="changes">
              <form
                name="form1"
                className="change"
                id="stage"
                action="#"
                onSubmit={(e) => e.preventDefault()}
              >
                {/* <label className="emails">Student Emails</label><br/>
                <textarea name="emails" id="pdEmails" value={selectedEmails}
                // rows={5}
                // cols={40}
                readOnly
                ref={inputElement1}
                // cols={((5*35*window.innerWidth)/4600)}
                // rows={(4600*5)/(5*35*window.innerWidth)}
                 ></textarea>  */}
                <TextField
                  id="inputElement1"
                  label="Student Emails"
                  multiline
                  rows={4}
                  fullWidth
                  value={!emailToggle ? selectedEmails : null}
                />
                <br />
                <br />
                {stageName == "Final Rejection" ? (
                  <div>
                    <TextField
                      id="inputElement2"
                      label="Notes/Reason Of Rejection"
                      multiline
                      rows={4}
                      fullWidth
                      value={selectedReason === "Other" ? null : selectedReason}
                      disabled={selectedReason !== "Other"}
                    />
                    <select
                      name="dd1"
                      id="dd1"
                      value={selectedReason}
                      onChange={handleReasonChange}
                    >
                      {reasonsOfRejection.map((item: any) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div>
                    <TextField
                      id="inputElement2"
                      label="Stage Change Reason"
                      multiline
                      rows={4}
                      fullWidth
                      value={null}
                    />
                  </div>
                )}

                <select
                  name="dd1"
                  id="dd1"
                  onChange={(event) => setStageName(event?.target.value)}
                >
                  {stage.map((item: any) => (
                    <option>{item}</option>
                  ))}
                </select>
              </form>
            </div>
            <div className="bottom-buttons">
              <Button
                variant="solid"
                color="danger"
                className="submitBtn"
                onClick={validateEmail}
              >
                Submit
              </Button>
              <Button
                variant="plain"
                color="neutral"
                onClick={() => setShowForm(false)}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
