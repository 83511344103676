import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  FormControlLabel,
  TextField as MaterialTextField,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { CustomQuestion, QuestionType } from "./JobDetailsForm";

interface IndividualCustomQuestionProps {
  data: CustomQuestion;
  idx: number;
  addCustomQuestion: () => void;
  removeCustomQuestion: (customQuestionIndex: number) => void;
  updateCustomQuestionTypeField: (
    customQuestionIndex: number,
    value: QuestionType
  ) => void;
  updateCustomQuestionQuestionField: (
    customQuestionIndex: number,
    value: string
  ) => void;
  addOption: (customQuestionIndex: number) => void;
  updateCustomQuestionCorrectAnswer: (
    customQuestionIndex: number,
    value: string
  ) => void;
  removeOption: (customQuestionIndex: number, optionIndex: number) => void;
  updateOption: (
    customQuestionIndex: number,
    optionIndex: number,
    value: string
  ) => void;
}

const IndividualCustomQuestion = ({
  data,
  idx,
  addCustomQuestion,
  removeCustomQuestion,
  updateCustomQuestionTypeField,
  updateCustomQuestionQuestionField,
  addOption,
  removeOption,
  updateOption,
  updateCustomQuestionCorrectAnswer,
}: IndividualCustomQuestionProps) => {
  return (
    <div className="individual-custom-question">
      <div>
        <h4>Question Type</h4>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            className="custom-question-type"
            value={data.questionType}
            onChange={(e) => {
              updateCustomQuestionTypeField(
                idx,
                e.target.value as QuestionType
              );
            }}
          >
            <FormControlLabel
              value={QuestionType.Text}
              control={<Radio />}
              label={QuestionType.Text}
            />
            <FormControlLabel
              value={QuestionType.Checkbox}
              control={<Radio />}
              label={QuestionType.Checkbox}
            />
            <FormControlLabel
              value={QuestionType.Radio}
              control={<Radio />}
              label={QuestionType.Radio}
            />
            <Button
              className="delete-button"
              onClick={() => {
                removeCustomQuestion(idx);
              }}
            >
              <CloseIcon />
            </Button>
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <h4>Question Statement</h4>
        <MaterialTextField
          required
          label="Enter Statement"
          value={data.questionString}
          onChange={(e) => {
            updateCustomQuestionQuestionField(idx, e.target.value);
          }}
        />
      </div>
      {data.answerOptions.length !== 0 && (
        <div className="options-container">
          <h4>Create Options</h4>
          <div>
            {data.answerOptions.map((option, i) => (
              <div className="option-row" key={i}>
                <MaterialTextField
                  required
                  label="Enter Choice/Option"
                  value={option}
                  onChange={(e) => {
                    updateOption(idx, i, e.target.value);
                  }}
                />
                <Button
                  className="add-button"
                  onClick={() => {
                    addOption(idx);
                  }}
                >
                  <AddIcon />
                </Button>
                <Button
                  className="delete-button"
                  onClick={() => {
                    removeOption(idx, i);
                  }}
                >
                  <CloseIcon />
                </Button>
              </div>
            ))}
            {data.questionType === QuestionType.Radio ? (
              <>
                <Typography sx={{ margin: "16px 0px" }}>
                  Please Select Correct Option (If Any)
                </Typography>
                <Select
                  sx={{ width: "100%" }}
                  value={data.correctAnswer}
                  onChange={(e) => {
                    updateCustomQuestionCorrectAnswer(idx, e.target.value);
                  }}
                >
                  {data.answerOptions.map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                  <MenuItem value={""}>None</MenuItem>
                </Select>
              </>
            ) : null}
          </div>
        </div>
      )}
      <div className="add-custom-question">
        <div></div>
        <Button
          className="add-custom-question-button"
          onClick={addCustomQuestion}
        >
          Add Question
        </Button>
      </div>
    </div>
  );
};

export default IndividualCustomQuestion;
