import * as api from "../api";
export const getBatchManagers = () => async (dispatch) => {
  try {
    const { data } = await api.getBatchManagers();
    dispatch({ type: "GET_BATCH_MANAGERS", payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const adminOverview = (batchManagers) => async (dispatch) => {
  let totalData = [];
  try {
    let i = 0;
    const promises = [];
    batchManagers.forEach((element) => {
      promises.push(
        api.getFullDataOfStudentsOfEachManagerForAdminView(element.BMId)
      );
    });
    const dataResponse = await Promise.all(promises);
    dataResponse.forEach((element) => {
      totalData = [...totalData, ...element.data];
    });
    dispatch({ type: "GET_ADMIN_DATA", payload: totalData });
  } catch (error) {
    console.error(error);
  }
};
