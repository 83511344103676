import {Checkbox, FormControl, InputLabel} from "@mui/material";
import {FormDataType, FormFieldProps} from "../JobDetailsForm";

const CheckBox = ({id, label, updateFormData, formData}: FormFieldProps) => {
  return (
    <div className="form-item">
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>

        <Checkbox
          //@ts-ignore
          checked={formData[id as keyof FormDataType]}
          onChange={(e) => {
            updateFormData(id, e.target.checked);
          }}
        />
      </FormControl>
    </div>
  );
};

export default CheckBox;
